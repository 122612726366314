import { useEffect } from "react";
import { auth } from "../firebase";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
} from "firebase/auth";
import { useAuth } from "../features/AuthSlice";

const useFirebase = () => {
  const { firebaseUser, setFirebaseUser } = useAuth();

  const googleSignIn = async (handleClosePopup) => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        // Handle the case where user closed the popup
        console.log("Popup closed by user");
        handleClosePopup();
      } else {
        // Handle other errors
        console.error(error);
        throw error;
      }
    }
    // signInWithRedirect(auth, provider)
  };

  const signUpWithEmailPassword = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        sendEmailVerification(user);
        console.log(`User: ${user}`);
        setFirebaseUser(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(
          `Error: \n\tCode: ${errorCode}\n\tMessage: ${errorMessage}`
        );
      });
  };

  const logOut = () => {
    signOut(auth);
  };

  const sendResetPasswordEmail = (email, callback) => {
    sendPasswordResetEmailFirebase(auth, email)
      .then(() => {
        callback();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(
          `Error: \n\tCode: ${errorCode}\n\tMessage: ${errorMessage}`
        );
      });
  };

  const sendVerificationEmailToUser = async (user, updateURLParams) => {
    try {
      if (!user.emailVerified) {
        await sendEmailVerification(user);
        updateURLParams(
          "Verification email sent. Please check your inbox.",
          false
        );
      } else {
        updateURLParams("Your email is already verified.", false);
      }
    } catch (error) {
      if (
        error.code === "auth/too-many-requests" ||
        (error.code === 400 && error.message === "TOO_MANY_ATTEMPTS_TRY_LATER")
      ) {
        updateURLParams(
          "Email verification pending. Please check your inbox for the verification message.",
          true
        );
      } else {
        updateURLParams(getFirebaseErrorMessage(error.code), true);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setFirebaseUser(currentUser);
      // console.log("User", currentUser);
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Map Firebase error codes to user-readable messages
  const getFirebaseErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "The email address is badly formatted.";
      case "auth/user-disabled":
        return "This account has been disabled.";
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/wrong-password":
        return "Incorrect password.";
      case "auth/email-already-in-use":
        return "The email address is already in use by another account.";
      case "auth/weak-password":
        return "The password is too weak.";
      case "auth/too-many-requests":
        return "Too many login attempts. Please try again later.";
      default:
        return "An unknown error occurred. Please try again.";
    }
  };

  return {
    googleSignIn,
    signUpWithEmailPassword,
    sendResetPasswordEmail,
    logOut,
    firebaseUser,
    setFirebaseUser,
    getFirebaseErrorMessage,
    sendVerificationEmailToUser,
  };
};

export default useFirebase;
