import React from "react";

const IconTitleComponent = ({ iconSrc, Icon, title }) => {
  return (
    <div className="flex gap-6 items-center">
      {iconSrc && <img src={iconSrc} className="size-[3.375em]" alt="" />}
      {Icon && <Icon className="size-[3.375em]" />}
      <p className="font-light text-3xl">{title}</p>
    </div>
  );
};

export default IconTitleComponent;
