const ApiConstants = Object.freeze({
  BASE_URL: process.env.REACT_APP_API_URL,
  
  // Endpoints - Auth
  ENDPOINT_LOGIN: process.env.REACT_APP_ENDPOINT_LOGIN,
  ENDPOINT_REFRESH_TOKEN: process.env.REACT_APP_ENDPOINT_REFRESH_TOKEN,
  
  // Endpoints - Dashboard
  ENDPOINT_GET_ACCOUNT_DETAILS: process.env.REACT_APP_ENDPOINT_GET_ACCOUNT_DETAILS,
  ENDPOINT_GET_NOTIFICATION_SETTINGS: process.env.REACT_APP_ENDPOINT_GET_NOTIFICATION_SETTINGS,
  ENDPOINT_UPDATE_NOTIFICATION_SETTINGS: process.env.REACT_APP_ENDPOINT_UPDATE_NOTIFICATION_SETTINGS,
  
  // Endpoints - Topup
  ENDPOINT_QUICK_TOP_UP: process.env.REACT_APP_ENDPOINT_QUICK_TOP_UP,
  ENDPOINT_UPLOAD_FILE: process.env.REACT_APP_ENDPOINT_UPLOAD_FILE,
  ENDPOINT_GET_FILES: process.env.REACT_APP_ENDPOINT_GET_FILES,
  ENDPOINT_GET_FILE_CONTENT: process.env.REACT_APP_ENDPOINT_GET_FILE_CONTENT,
  ENDPOINT_DELETE_FILE: process.env.REACT_APP_ENDPOINT_DELETE_FILE,
  FILE_ID_PATH_VARIABLE_KEY: process.env.REACT_APP_ENDPOINT_DELETE_FILE_FILE_ID_PATH_VARIABLE_KEY,
  ENDPOINT_CREATE_CAMPAIGN: process.env.REACT_APP_ENDPOINT_CREATE_CAMPAIGN,

  // Endpoints - Wallet
  ENDPOINT_WALLET_GET_ALL_CARDS: process.env.REACT_APP_ENDPOINT_WALLET_GET_ALL_CARDS,
  ENDPOINT_WALLET_GET_CARD_BY_ID: process.env.REACT_APP_ENDPOINT_WALLET_GET_CARD_BY_ID,
  CARD_ID_PATH_VARIABLE_KEY: process.env.REACT_APP_ENDPOINT_WALLET_GET_CARD_BY_ID_PATH_VARIABLE_KEY,
  ENDPOINT_WALLET_ADD_CARD: process.env.REACT_APP_ENDPOINT_WALLET_ADD_CARD,
  ENDPOINT_WALLET_DELETE_CARD: process.env.REACT_APP_ENDPOINT_WALLET_DELETE_CARD,
  ENDPOINT_WALLET_SET_TO_DEFAULT: process.env.REACT_APP_ENDPOINT_WALLET_SET_TO_DEFAULT,
  ENDPOINT_WALLET_GET_TRANSCACTIONS: process.env.REACT_APP_ENDPOINT_WALLET_GET_TRANSCACTIONS,
  // Endpoints - Wallet => Payment
  ENDPOINT_WALLET_INIT_SESSION: process.env.REACT_APP_ENDPOINT_WALLET_INIT_SESSION,
  ENDPOINT_WALLET_INIT_AUTH: process.env.REACT_APP_ENDPOINT_WALLET_INIT_AUTH,
  
  // Endpoints - Reports
  ENDPOINT_GET_REPORTS: process.env.REACT_APP_ENDPOINT_GET_REPORTS,
  ENDPOINT_GET_REPORT_DETAILS: process.env.REACT_APP_ENDPOINT_GET_REPORT_DETAILS,
  ENDPOINT_GET_REPORT_STATUS: process.env.REACT_APP_ENDPOINT_GET_REPORT_STATUS,
  ENDPOINT_DOWNLOAD_REPORT: process.env.REACT_APP_ENDPOINT_DOWNLOAD_REPORT,

  // Endpoints - Credit Limits
  ENDPOINT_GET_CREDIT_LIMITS: process.env.REACT_APP_ENDPOINT_GET_CREDIT_LIMITS
});

export default ApiConstants;
