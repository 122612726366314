import React from 'react'
import { Link } from 'react-router-dom'
import PATHS from '../constants/RoutePaths'

const Footer = () => {
  return (
    <footer className='grid grid-cols-3 px-[1.875em] py-[1.375em] bg-sbg border-t-[0.3px] border-t-brdr'>
        <div className='flex gap-12'>
          <Link className="page-link" to={PATHS.USER_TERMS}>Terms of User</Link>
          {/* <Link className="page-link" to={PATHS.PRIVACY_POLICY}>Privacy Policy</Link> */}
        </div>
        <div className='text-center'>2024 PlanetBeyond All right reserved</div>
    </footer>
  )
}

export default Footer