import React, { useEffect } from "react";
import IconTitleComponent from "./IconTitleComponent";
import formatAmount from "../../functions/formatAmount";
import Switch from "../../reusable/Switch";
import { ReactComponent as BalanceIcon } from "../../assets/svgs/balance-icon.svg";
import useGetNotificationSettings from "../../hooks/notifcationEmail/useGetNotificationSettings";
import { useUser } from "../../features/UserSlice";
import useUpdateNotificationSettings from "../../hooks/notifcationEmail/useUpdateNotificationSettings";

const MyBalance = () => {
  const { userBalance, notifyUserByEmail, notifyUserByEmailBalanceThreshold } =
    useUser();
  const { getNotificationSettings, loading: fetchingNotificationSetting } =
    useGetNotificationSettings();
  const { updateNotificationSettings, loading: updatingNotificationSetting } =
    useUpdateNotificationSettings();
  const handleNotifyByEmailWhenBalanceRunsLowClick = () => {
    updateNotificationSettings(!notifyUserByEmail);
  };

  useEffect(() => {
    getNotificationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="rounded-md bg-sbg p-4 laptop:p-[1.875em] flex flex-col items-center gap-14 w-full">
      <div className="w-full">
        <IconTitleComponent Icon={BalanceIcon} title={"My Balance"} />
      </div>
      <p className="font-bold text-[2.5em] leading-5">
        {formatAmount(userBalance)}
      </p>
      <div className="flex gap-4">
        <Switch
          checked={notifyUserByEmail}
          onChange={handleNotifyByEmailWhenBalanceRunsLowClick}
          loading={fetchingNotificationSetting || updatingNotificationSetting}
        />
        <p className="text-lg">
          Notify me via email when my balance runs lower than{" "}
          {formatAmount(notifyUserByEmailBalanceThreshold)}
        </p>
      </div>
    </div>
  );
};

export default MyBalance;
