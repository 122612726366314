// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-dot {
  /* animation-name: floating-dot-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite; */
  animation: floating-dot-anim forwards ease-in-out infinite;
}

@keyframes floating-dot-anim {
  0% {
    width: 0%;
    opacity: 100;
  }
  49% {
    opacity: 0;
    width: 100%;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 100    ;
    width: 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/sending-payment.css"],"names":[],"mappings":"AAAA;EACE;;wCAEsC;EACtC,0DAA0D;AAC5D;;AAEA;EACE;IACE,SAAS;IACT,YAAY;EACd;EACA;IAKE,UAAU;IACV,WAAW;EAHb;EAKA;IACE,UAAU;EACZ;EACA;IACE,gBAAgB;IAChB,SAAS;EACX;AACF","sourcesContent":[".floating-dot {\n  /* animation-name: floating-dot-anim;\n  animation-duration: 1s;\n  animation-iteration-count: infinite; */\n  animation: floating-dot-anim forwards ease-in-out infinite;\n}\n\n@keyframes floating-dot-anim {\n  0% {\n    width: 0%;\n    opacity: 100;\n  }\n  49% {\n    opacity: 100;\n    width: 100%;\n  }\n  49% {\n    opacity: 0;\n    width: 100%;\n  }\n  99% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 100    ;\n    width: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
