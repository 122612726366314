import React from "react";
import checkIcon from "../assets/svgs/check-icon.svg";

const CheckBox = ({ id, name, checked, onChange, ...props }) => {
  return (
    <label>
      <input
        {...props}
        className="hidden"
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div
        className={`m-1 size-[1.625em] cursor-pointer transition-all duration-100 border-[1.5px] rounded-[5px] ${
          checked
            ? "border-ptxt bg-ptxt grid place-items-center"
            : " border-stxt bg-transparent "
        }`}
      >
        <img
          src={checkIcon}
          className={`h-[11.25px] ${checked ? "opacity-100" : "opacity-0"}`}
          alt=""
        />
      </div>
    </label>
  );
};

export default CheckBox;
