import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PATHS from "../../constants/RoutePaths";
import { ReactComponent as BackArrow } from "../../assets/svgs/back-arrow.svg";
import Networks, { getNetwork, getNetworkByName } from "../../data/Networks";
import { toTitleCase } from "../../functions/stringFunctions";
import CheckBox from "../../reusable/CheckBox";
import useApp from "../../context/AppState";
import PaymentSendingPopup from "../../reusable/PaymentSendingPopup";
import PaymentSentPopup from "../../reusable/PaymentSentPopup";
import { useAuth } from "../../features/AuthSlice";
import useDoQuickTopUp from "../../hooks/topup/useDoQuickTopUp";
import useGetAccountDetails from "../../hooks/useGetAccountDetails";
import { useUser } from "../../features/UserSlice";
import PaymentFailedPopUp from "../../reusable/PaymentFailedPopUp";

const MultipleMsisdnAmountForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showPopup } = useApp();
  const { getNetworkRuleByName } = useAuth();
  const { msisdns: values } = location.state || {};

  const getMinMessage = (networkName, minValueAllowed, amount) =>
    `Rs ${
      amount || 0
    } amount is below the minimum amount of operator. Minimum amount for Operator ${toTitleCase(
      networkName
    )} is Rs ${minValueAllowed}.`;

  const getMaxMessage = (networkName, maxValueAllowed, amount) =>
    `Rs ${
      amount || 0
    } amount is below the maximum amount of operator. Maximum amount for Operator ${toTitleCase(
      networkName
    )} is Rs ${maxValueAllowed}.`;

  // Retrieve the 'p' parameter
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const maxPages = Math.ceil(values?.length / pageSize);

  const [msisdns, setMsisdns] = useState(
    values.map((entry, index) => {
      return {
        id: index,
        msisdn: entry,
        operator: getNetwork(entry)?.networkName,
      };
    })
  );

  const [sameAmountForAllMSISDN, setSameAmountForAllMSISDN] = useState(false);
  const [sameAmountForAllMSISDNAmount, setSameAmountForAllMSISDNAmount] =
    useState("");
  const toggleSameAmountForAllMSISDN = () => {
    setSameAmountForAllMSISDN((val) => !val);
    setSameAmountForAllMSISDNAmount("");
  };
  const handelChangeSameAmountForAllMSISDNAmountValue = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setSameAmountForAllMSISDNAmount(value);
      setMsisdns((msisdns) =>
        msisdns.map((msisdn) => {
          const networkName = msisdn?.operator;
          const network = getNetworkByName(networkName);
          const networkRules = getNetworkRuleByName(network?.networkName);
          const amount = value;
          // Verification Logic Here
          let remarks = null;
          if (amount < networkRules?.minCreditLimit) {
            remarks = getMinMessage(
              networkName,
              networkRules?.minCreditLimit,
              amount
            );
          } else if (amount > networkRules?.maxCreditLimit) {
            remarks = getMaxMessage(
              networkName,
              networkRules?.maxCreditLimit,
              amount
            );
          }
          return { ...msisdn, amount: value, remarks: remarks };
        })
      );
    }
  };

  const [isNextAllowed, setIsNextAllowed] = useState(false);

  useEffect(() => {
    const isValid = msisdns.every((item) => {
      // Check 'amount' if it exists, ensuring it's not null, and greater than 0
      const validAmount = item.amount !== null && parseFloat(item.amount) > 0;

      // Ensure 'remarks' is either null or an empty string
      const validRemarks = item.remarks == null || item.remarks.trim() === "";

      // All conditions must be true
      return validAmount && validRemarks;
    });
    setIsNextAllowed(isValid);
  }, [msisdns]);

  const displayedValues = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    if (!Array.isArray(values)) {
      return [];
    }
    return msisdns.slice(startIndex, startIndex + pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, msisdns]);

  if (!values || !Array.isArray(values) || values?.length === 0) {
    return <Navigate to={PATHS.TOPUP} />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChange = (event, entry) => {
    const { name, value } = event.target;
    setMsisdns((msisdns) =>
      msisdns.map((msisdn) => {
        const networkName = name === "operator" ? value : entry?.operator;
        const network = getNetworkByName(networkName);
        const networkRules = getNetworkRuleByName(network?.networkName);
        const amount = name === "amount" ? value : entry?.amount;
        // Verification Logic Here
        let remarks = null;
        if (amount < networkRules?.minCreditLimit) {
          remarks = getMinMessage(
            networkName,
            networkRules?.minCreditLimit,
            amount
          );
        } else if (amount > networkRules?.maxCreditLimit) {
          remarks = getMaxMessage(
            networkName,
            networkRules?.maxCreditLimit,
            amount
          );
        }
        return msisdn.id === entry?.id
          ? { ...msisdn, [name]: value, remarks: remarks }
          : msisdn;
      })
    );
  };

  const handleNext = () => {
    showPopup(<PopUp data={msisdns} />);
  };

  return (
    <section className="space-y-5 flex flex-col h-full">
      <div className="flex gap-6 items-center">
        <button onClick={handleGoBack}>
          <BackArrow className="size-[1.625em] hover:scale-105" />{" "}
        </button>
        <p className="heading-text">{`Multiple Msisdn`}</p>
      </div>
      <section className={`relative bg-sbg flex-grow overflow-hidden`}>
        <section className={`flex flex-col h-full`}>
          <div className="p-6 flex-grow overflow-y-auto">
            <table className="min-w-full table-auto text-left">
              <thead>
                <tr className="bg-tbg">
                  <th className="report-table-header">MSISDN</th>
                  <th className="report-table-header">Operator Name</th>
                  <th className="report-table-header">Amount</th>
                  <th className="report-table-header">Remarks</th>
                  <th className="report-table-header !text-stxt !font-light text-[0.8125em]">
                    <div>
                      {maxPages > 0 && (
                        <div className="flex justify-end gap-6 flex-wrap">
                          <p>{`Showing ${(page - 1) * pageSize + 1}-${
                            (page - 1) * pageSize + displayedValues.length
                          }`}</p>
                          <div className="flex gap-6 items-center">
                            <button
                              disabled={page <= 1}
                              onClick={() => setPage(page - 1)}
                              className="text-stxt text-lg disabled:opacity-20"
                            >
                              &lt;
                            </button>
                            <button
                              disabled={page >= maxPages}
                              onClick={() => setPage(page + 1)}
                              className="text-stxt text-lg disabled:opacity-20"
                            >
                              &gt;
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayedValues.map((entry) => (
                  <tr key={entry?.id} className="report-table-row">
                    <td className="report-table-cell">{entry?.msisdn}</td>
                    <td className="report-table-cell !text-ttxt">
                      <select
                        className="bg-sbg cursor-pointer focus:outline-none"
                        name="operator"
                        value={entry?.operator}
                        onChange={(event) => handleChange(event, entry)}
                      >
                        {Networks.map((network, index) => (
                          <option key={index} value={network?.networkName}>
                            {toTitleCase(network?.networkName)}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className={`report-table-cell`}>
                      <span
                        className={`bg-pbg py-2 px-2.5 rounded ${
                          entry?.remarks ? "border-[1px] border-cyellow" : ""
                        } ${
                          sameAmountForAllMSISDN
                            ? "opacity-50 pointer-events-none"
                            : ""
                        }`}
                      >
                        Rs&nbsp;
                        <input
                          type="tel"
                          name="amount"
                          value={entry?.amount || ""}
                          onChange={(event) => {
                            if (!isNaN(event.target.value)) {
                              handleChange(event, entry);
                            }
                          }}
                          className="bg-transparent text-ptxt focus:outline-none w-32"
                          maxLength={6}
                        />
                      </span>
                    </td>
                    <td
                      colSpan={2}
                      className={`report-table-cell !text-left !text-cyellow !text-xs text-wrap w-[15vw]`}
                    >
                      {entry?.remarks}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="bg-tbg py-3 px-7 flex justify-between items-center gap-4">
            <div className="flex items-center gap-12">
              <label
                htmlFor="sameAmountForAllMSISDN"
                className="flex gap-5 items-center cursor-pointer"
              >
                <CheckBox
                  id="sameAmountForAllMSISDN"
                  checked={sameAmountForAllMSISDN}
                  onChange={toggleSameAmountForAllMSISDN}
                />
                <span
                  className={`select-none transition-all duration-100 ${
                    sameAmountForAllMSISDN ? "text-ptxt" : "text-stxt"
                  }`}
                >
                  Set Same Amount for all MSISDN
                </span>
              </label>
              <span
                className={`bg-pbg py-2 px-2.5 rounded text-stxt transition-all duration-100 ${
                  sameAmountForAllMSISDN ? "" : "opacity-50 pointer-events-none"
                }`}
              >
                Rs&nbsp;
                <input
                  type="tel"
                  name="amount"
                  value={sameAmountForAllMSISDNAmount}
                  onChange={handelChangeSameAmountForAllMSISDNAmountValue}
                  className="bg-transparent text-ptxt focus:outline-none w-32"
                  maxLength={6}
                />
              </span>
            </div>
            <div>
              <div className="flex justify-center w-full gap-5">
                <button
                  className="btn-secondary rounded-sm"
                  onClick={handleGoBack}
                >
                  <span className="select-none px-8 ">Back</span>
                </button>
                <button
                  className="btn rounded-sm"
                  onClick={handleNext}
                  disabled={!isNextAllowed}
                >
                  <span className="select-none px-8 ">Next</span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default MultipleMsisdnAmountForm;

const PopUp = ({ data }) => {
  const { closePopup } = useApp();
  const { doQuickTopUp, formatRequestData } = useDoQuickTopUp();
  const { getAccountDetails } = useGetAccountDetails();
  const { userBalance } = useUser();
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const steps = Object.freeze({
    CONFIRM_TRANSACTION: 0,
    SENDING_PAYMENT: 1,
    PAYMENT_SENT: 2,
    PAYMENT_FAILED: 3,
  });
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(steps.CONFIRM_TRANSACTION);

  const formattedData = useMemo(() => {
    const seperatedData = separateByOperator(data);
    const result = [];
    Object.entries(seperatedData).forEach(([key, value]) => {
      result.push({
        operator: key,
        msisdnCount: value?.length,
        totalAmount: value.reduce((sum, item) => {
          return +sum + +item?.amount;
        }, 0),
      });
    });
    return result;
  }, [data]);
  const totalsData = useMemo(() => {
    return {
      msisdnCount: formattedData.reduce((sum, item) => {
        return +sum + +item?.msisdnCount;
      }, 0),
      totalAmount: formattedData.reduce((sum, item) => {
        return +sum + +item?.totalAmount;
      }, 0),
    };
  }, [formattedData]);

  useEffect(() => {
    getAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formattedData.length === 0) {
    return (
      <>
        <button className="btn" onClick={closePopup}>
          Close
        </button>
      </>
    );
  }

  const handleNext = async () => {
    setStep(steps.SENDING_PAYMENT);
    const result = await doQuickTopUp(
      data.map((entry) =>
        formatRequestData(entry?.msisdn, entry?.operator, entry?.amount)
      )
    );
    if (result.status) {
      setStep(steps.PAYMENT_SENT);
      getAccountDetails();
    } else {
      setStep(steps.PAYMENT_FAILED);
      setErrorMessage(result.message);
    }
  };

  const paymentSentOnCancel = () => {
    closePopup();
    navigate(PATHS.TOPUP);
  };
  const paymentSentOnViewReceipt = () => {
    closePopup();
    navigate(PATHS.REPORTS);
  };

  const doNotHasEnoughBalance = userBalance < totalsData?.totalAmount;

  return step === steps.CONFIRM_TRANSACTION ? (
    <div className="py-9 px-16 bg-sbg flex flex-col items-center gap-14  rounded-md">
      <p className="font-light text-3xl">Please confirm your payment request</p>
      <div className="grid grid-cols-2">
        <div className="flex items-center h-full min-w-60">
          <div className="space-y-10 w-full border-r-[0.5px] border-stxt">
            <div className="space-y-2.5">
              <p className="font-light text-[1.375em] leading-5">
                Total MSISDNs
              </p>
              <p className="font-semibold text-[1.625em] leading-5 text-ttxt">
                {totalsData?.msisdnCount}
              </p>
            </div>
            <div className="space-y-2.5">
              <p className="font-light text-[1.375em] leading-5">
                Total Amount
              </p>
              <p className="font-semibold text-[1.625em] leading-5 text-ttxt">
                {totalsData?.totalAmount}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center h-full pl-16">
          <div className="space-y-4">
            {formattedData.map((item, index) => (
              <div key={index}>
                <p className="text-lg">{toTitleCase(item.operator)}</p>
                <div className="flex justify-between gap-9 font-light text-stxt">
                  <div>Msisdns: {item.msisdnCount}</div>
                  <div>T.Amount: {item.totalAmount}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {doNotHasEnoughBalance && (
        <div className="flex justify-center text-center">
          <p className="text-cyellow underline">Not Enough Balance</p>
        </div>
      )}
      <div className="flex justify-center w-full gap-12">
        <button className="btn-secondary rounded-sm" onClick={closePopup}>
          <span className="select-none px-8 ">Cancel</span>
        </button>
        <button
          className="btn rounded-sm"
          disabled={doNotHasEnoughBalance}
          onClick={handleNext}
        >
          <span className="select-none px-8 ">Confirm</span>
        </button>
      </div>
    </div>
  ) : step === steps.SENDING_PAYMENT ? (
    <PaymentSendingPopup />
  ) : step === steps.PAYMENT_SENT ? (
    <PaymentSentPopup
      onCancel={paymentSentOnCancel}
      onViewReceipt={paymentSentOnViewReceipt}
    />
  ) : (
    <PaymentFailedPopUp
      onCancel={paymentSentOnCancel}
      errorMessage={errorMessage}
    />
  );
};

// Function to separate the array by operator
function separateByOperator(dataArray) {
  return dataArray.reduce((acc, obj) => {
    // If the operator key doesn't exist in the accumulator, create an empty array
    if (!acc[obj.operator]) {
      acc[obj.operator] = [];
    }
    // Push the current object into the relevant operator's array
    acc[obj.operator].push(obj);
    return acc;
  }, {});
}
