import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { REPORT_DETAIL_ROUTE_REPORT_ID_KEY } from "../../constants/RoutePaths";
import { decryptString } from "../../functions/Encryption";
import NotFound from "../../pages/NotFound";
import backArrow from "../../assets/svgs/back-arrow.svg";
import Loader from "../../reusable/Loader";
// import backIcon from "../../assets/svgs/back-icon.svg";
// import backIconDisabled from "../../assets/svgs/back-icon-disabled.svg";
// import nextIcon from "../../assets/svgs/next-icon.svg";
// import nextIconDisabled from "../../assets/svgs/next-icon-disabled.svg";
import useGetReport from "../../hooks/reports/useGetReportDetails";
import useGetReportStatus from "../../hooks/reports/useGetReportStatus";
import downloadReportIcon from "../../assets/svgs/download-report-icon.svg";
import useDownloadReport from "../../hooks/reports/useDownloadReport";

const TransactionDetail = () => {
  const pathParams = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const transactionId = decryptString(
    pathParams[REPORT_DETAIL_ROUTE_REPORT_ID_KEY]
  );
  const { reportStatus, loading: fetchingReportStatus } =
    useGetReportStatus(transactionId);
  const { downloadReport, loading: downloadingReport } =
    useDownloadReport(transactionId);
  const {
    transactionsFormatted,
    transactionsCount,
    fetching: loading,
    // eslint-disable-next-line no-unused-vars
    setFilter: updateFilter,
  } = useGetReport();
  // Retrieve the 'p' parameter
  const page = +searchParams.get("p") || null;
  const setPage = (value) => setSearchParams({ p: value });
  const pageSize = 7;
  const maxPages = Math.ceil(transactionsCount?.count / pageSize);

  // Set 'p' to 0 if it is null
  useEffect(() => {
    if (!page || page < 1) {
      setSearchParams({ p: "1" });
    }
  }, [page, setSearchParams]);

  useEffect(() => {
    if (page >= 1) {
      updateFilter(transactionId, page, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!transactionId) {
    return <NotFound />;
  }

  const handleGoBack = () => {
    navigate(-2);
  };
  return (
    <section className="space-y-5 flex flex-col h-full">
      <div className="flex gap-6 items-center">
        <button onClick={handleGoBack}>
          <img className="size-[1.625em]" src={backArrow} alt="<" />
        </button>
        <p className="heading-text">{`Transaction ID: ${transactionId}`}</p>
      </div>
      <section className={`relative bg-sbg flex-grow overflow-hidden`}>
        {loading ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <section
          className={`${
            loading ? "opacity-0 pointer-events-none" : ""
          } flex flex-col h-full`}
        >
          <div className="px-0 pb-6 flex-grow overflow-y-auto">
            <table className="min-w-full table-auto text-left">
              <thead>
                <tr className="bg-tbg">
                  <th className="report-table-header">MSISDN</th>
                  <th className="report-table-header">Operator Name</th>
                  <th className="report-table-header">Amount</th>
                  <th className="report-table-header">Status</th>
                  <th className="report-table-header !text-stxt !font-light text-[0.8125em]">
                    <div>
                      {maxPages > 0 && (
                        <div className="flex justify-end gap-6">
                          <p>{`Showing ${(page - 1) * pageSize + 1}-${
                            (page - 1) * pageSize + transactionsFormatted.length
                          }`}</p>
                          <button
                            disabled={page <= 1}
                            onClick={() => setPage(page - 1)}
                            className="text-stxt text-lg disabled:opacity-20"
                          >
                            &lt;
                            {/* <img
                              className="sixe-[0.875em]"
                              src={page <= 1 ? backIconDisabled : backIcon}
                              alt="<"
                            /> */}
                          </button>
                          <button
                            disabled={page >= maxPages}
                            onClick={() => setPage(page + 1)}
                            className="text-stxt text-lg disabled:opacity-20"
                          >
                            &gt;
                            {/* <img
                              className="sixe-[0.875em]"
                              src={
                                page >= maxPages ? nextIconDisabled : nextIcon
                              }
                              alt=">"
                            /> */}
                          </button>
                        </div>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactionsFormatted.map((transaction, index) => (
                  <tr key={index} className="report-table-row">
                    <td className="report-table-cell !text-ttxt">
                      {transaction?.msisdn}
                    </td>
                    <td className="report-table-cell">
                      {transaction?.operator || "Unknown"}
                    </td>
                    <td className="report-table-cell">{transaction?.amount}</td>
                    <td
                      className={`report-table-cell ${transaction?.status?.className}`}
                    >
                      {transaction?.status?.text}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="bg-tbg py-3 flex items-center px-7 justify-between w-full">
            <div className="flex justify-center gap-14 px-5">
              {transactionsCount?.count ? (
                <div className="flex gap-4">
                  <div className="text-stxt text-lg">Total MSISDNs</div>
                  <div className="text-lg font-[500]">
                    {transactionsCount?.count}
                  </div>
                </div>
              ) : null}
              {transactionsCount?.amount ? (
                <div className="flex gap-4">
                  <div className="text-stxt text-lg">Total Amount</div>
                  <div className="text-lg font-500">
                    {transactionsCount?.amount}
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              {fetchingReportStatus ? null : reportStatus?.isDownloadable ? (
                <button
                  disabled={downloadingReport}
                  className="btn "
                  onClick={downloadReport}
                >
                  <div className="flex gap-2 items-center">
                    <img className="size-4" src={downloadReportIcon} alt="" />
                    <p>{downloadingReport ? "Downloading" : "Download"}</p>
                  </div>
                </button>
              ) : (
                <button className="btn" disabled>
                  {reportStatus?.text}
                </button>
              )}
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default TransactionDetail;
