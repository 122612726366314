import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <section className="h-svh grid place-items-center">
      <div className="flex flex-col gap-3 items-center">
        <h1 className="text-3xl font-bold">404 - No Page Found :(</h1>
        <button className="hover:underline" onClick={goBack}>Back</button>
      </div>
    </section>
  );
};

export default NotFound;
