import React, { useEffect, useRef, useState } from "react";
import "../styles/side-bar.css";
import balanceBoostByPlanetBeyondLogo from "../assets/images/BalanceBoostByPlanetBeyond.png";
import personIcon from "../assets/images/person.png";
import { ReactComponent as Home_Icon } from "../svgs/sidebar/Home-Icon.svg";
import { ReactComponent as Topup_Icon } from "../svgs/sidebar/Topup-Icon.svg";
import { ReactComponent as Wallet_Icon } from "../svgs/sidebar/wallet-icon.svg";
import { ReactComponent as Reports_Icon } from "../svgs/sidebar/Reports-icon.svg";
import { ReactComponent as Profile_Icon } from "../svgs/sidebar/profile-icon.svg";
import { ReactComponent as Logout_Icon } from "../svgs/sidebar/Logout-icon.svg";
import { Link, useLocation } from "react-router-dom";
import PATHS from "../constants/RoutePaths";
import useApp from "../context/AppState";
import LogoutPopUp from "./LogoutPopUp";
import { useAuth } from "../features/AuthSlice";
import ImageFetcher from "../reusable/ImageFetcher";
import formatAmount from "../functions/formatAmount";
import { useUser } from "../features/UserSlice";
import { ReactComponent as BurgerBtn} from "../assets/svgs/burger-btn.svg";
import useUpdateUserBalance from "../hooks/useUpdateUserBalance";
import { ReactComponent as Reload } from "../svgs/reload.svg";

const SideBar = () => {
  const [isOpened, setOpened] = useState(false);
  const toggelOpen = () => setOpened((prev) => !prev);
  return (
    <>
      <aside className="hidden tablet:block side-bar-container custom-scrollbar">
        <LogoSection />
        <ProfileSection />
        <SideBarMenu />
      </aside>
      <header
        className={`tablet:hidden ${
          isOpened
            ? "h-svh w-screen transition-all absolute top-0 z-[9999]"
            : "h-14"
        } bg-sbg`}
      >
        <div className="py-2 px-4 flex justify-between items-center flex-wrap">
          <div className="flex items-center gap-4">
            <button onClick={toggelOpen}>
              <BurgerBtn className="w-6" />
            </button>
            <img className="h-6" src={balanceBoostByPlanetBeyondLogo} alt="" />
          </div>
          <div>
            <ProfileSection />
          </div>
        </div>
        {isOpened && (
          <div>
            <SideBarMenu onItemClick={toggelOpen} />
          </div>
        )}
      </header>
    </>
  );
};

const LogoSection = () => {
  return (
    <div className="side-bar-logo-section-container side-bar-bottom-border">
      <div>
        <img
          className="w-[12.8125em]"
          src={balanceBoostByPlanetBeyondLogo}
          alt="Balance Boost"
        />
      </div>
    </div>
  );
};

const ProfileSection = () => {
  const { firebaseUser } = useAuth();
  const { userBalance, user } = useUser();
  const imgClass = "rounded-full size-6 tablet:size-[5.625em]";
  const [fetchingBalance, setFetchingBalance] = useState(false);
  const updateUserBalance = useUpdateUserBalance();

  const [rotationAngle, setRotationAngle] = useState(0); // Track the rotation angle
  const rotationRef = useRef(null); // Ref to store the rotation animation
  useEffect(() => {
    // The function to handle continuous rotation
    const animateRotation = () => {
      if (fetchingBalance) {
        setRotationAngle((prevAngle) => prevAngle + 7); // Increase rotation angle by 1 degree
        rotationRef.current = requestAnimationFrame(animateRotation); // Continue the animation
      }
    };

    // Start or stop animation based on the isRotating state
    if (fetchingBalance) {
      rotationRef.current = requestAnimationFrame(animateRotation);
    } else {
      cancelAnimationFrame(rotationRef.current); // Stop animation when isRotating is false
    }

    // Cleanup on component unmount or when the condition changes
    return () => cancelAnimationFrame(rotationRef.current);
  }, [fetchingBalance]); // Re-run this effect whenever `isRotating` changes

  const [fetchBalanceFromApi, setFetchBalanceFromApi] = useState(true);

  const handleUpdateBalance = async () => {
    setFetchingBalance(true);
    if (fetchBalanceFromApi) {
      setFetchBalanceFromApi(false);
      await updateUserBalance();
      setFetchingBalance(false);
      setTimeout(() => {
        setFetchBalanceFromApi(true);
      }, 20000);
    } else {
      setTimeout(() => {
        setFetchingBalance(false);
      }, 2000);
    }
  };

  return (
    <div className="flex flex-row-reverse tablet:flex-col items-center gap-7 w-full tablet:py-6 tablet:side-bar-bottom-border">
      <div>
        {firebaseUser?.photoURL ? (
          <ImageFetcher className={imgClass} src={firebaseUser?.photoURL} />
        ) : (
          <img className={imgClass} src={personIcon} alt="User" />
        )}
      </div>
      <div className="text-center tablet:font-[500]">
        <p className="tablet:font-[500] text-xs tablet:text-2xl">
          {user?.fullName}
        </p>
        <div className="flex justify-center items-center">
          <p
            className={`text-[0.625em] tablet:text-lg ${
              fetchingBalance ? "text-stxt" : "text-ttxt"
            }`}
          >
            {formatAmount(userBalance)}
          </p>
          <button
            onClick={handleUpdateBalance}
            disabled={fetchingBalance}
            className={`text-[0.625em] tablet:text-lg hover:scale-125 disabled:pointer-events-none transition-all duration-100  ${
              fetchingBalance ? "text-ttxt" : "text-stxt"
            }`}
          >
            <Reload
              style={{
                transform: `rotate(-${rotationAngle}deg)`,
              }}
              className="size-4"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const SideBarMenu = ({ onItemClick = () => {} }) => {
  const { showPopup } = useApp();
  const location = useLocation();
  const pathName = location.pathname;
  const handleLogout = () => {
    showPopup(<LogoutPopUp />);
  };
  const items = [
    {
      name: "Dashboard",
      isActive: pathName.startsWith(PATHS.DASHBOARD) || pathName === PATHS.BASE,
      icon: Home_Icon,
      isLink: true,
      to: PATHS.DASHBOARD,
    },
    {
      name: "Topup",
      isActive: pathName.startsWith(PATHS.TOPUP),
      icon: Topup_Icon,
      isLink: true,
      to: PATHS.TOPUP,
    },
    {
      name: "My Wallet",
      isActive: pathName.startsWith(PATHS.WALLET),
      icon: Wallet_Icon,
      isLink: true,
      to: PATHS.WALLET,
    },
    {
      name: "Reports",
      isActive: pathName.startsWith(PATHS.REPORTS),
      icon: Reports_Icon,
      isLink: true,
      to: PATHS.REPORTS,
    },
    {
      name: "Profile",
      isActive: pathName.startsWith(PATHS.PROFILE),
      icon: Profile_Icon,
      isLink: true,
      to: PATHS.PROFILE,
    },
    {
      name: "Logout",
      isActive: false,
      icon: Logout_Icon,
      isLink: false,
      onClick: handleLogout,
    },
  ];
  return (
    <div>
      <div className="px-10 py-7 text-stxt text-[0.875em]">Menu</div>
      {items.map((item, index) => (
        <SideBarItem key={index} item={item} onItemClick={onItemClick} />
      ))}
    </div>
  );
};

const SideBarItem = ({ item, onItemClick = () => {} }) => {
  const [isFocused, setFocused] = useState(item.isActive);
  const Icon = item.icon;

  const content = (
    <div className={`bg-ttxt`}>
      <div
        onMouseEnter={() => setFocused(true)} // Change to hover image on mouse enter
        onMouseLeave={() => setFocused(false)} // Revert to default image on mouse leave
        className={`${
          item.isActive || isFocused
            ? "bg-[#2a3755] text-ttxt font-[500]"
            : "bg-sbg"
        } ${
          item.isActive
            ? "ml-2 pl-4 tablet:pl-6 laptop:pl-8"
            : "pl-6 tablet:pl-8 laptop:px-10"
        } py-4 transition-all duration-300 flex items-center gap-5 hover:text-ttxt `}
      >
        <Icon className={`size-6 ${item.isActive ? "" : "opacity-50"}`} />
        <p>{item.name}</p>
      </div>
    </div>
  );
  return item.isLink ? (
    <Link onClick={onItemClick} to={item.to}>
      {content}
    </Link>
  ) : (
    <button
      className="w-full"
      onClick={() => {
        onItemClick();
        typeof item.onClick === "function" && item.onClick();
      }}
    >
      {content}
    </button>
  );
};

export default SideBar;
