import React, { forwardRef, useImperativeHandle } from "react";
import formatAmount from "../../functions/formatAmount";
import formatDate from "../../functions/formatDate";
import useGetWalletTransactions from "../../hooks/wallet/useGetWalletTransactions";
import Loader from "../../reusable/Loader";

const TransactionHistory = forwardRef((props, ref) => {
  const {
    formattedData: entries,
    loading,
    totalPages: maxPages,
    refreshTransactionsData,
    page,
    setPage,
    pageSize,
  } = useGetWalletTransactions();

  useImperativeHandle(ref, () => ({
    refreshTransactionsData: () => {
      if (page === 0) {
        refreshTransactionsData();
      } else {
        setPage(0);
      }
    },
  }));

  if (entries.length === 0 && !loading && page === 0) {
    return <></>;
  }
  return (
    <section className={`relative bg-sbg flex-grow overflow-hidden`}>
      {loading ? (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <section
        className={`${
          loading ? "opacity-0 pointer-events-none" : ""
        } flex flex-col h-full`}
      >
        <div className="p-6 flex-grow overflow-y-auto">
          <table className="min-w-full table-auto text-left">
            <thead>
              <tr className="bg-tbg">
                <th className="report-table-header">Transaction ID</th>
                <th className="report-table-header">Date</th>
                <th className="report-table-header">Amount</th>
                <th className="report-table-header !text-stxt !font-light text-[0.8125em]">
                  <div>
                    {maxPages > 0 && (
                      <div className="flex justify-end gap-6 flex-wrap">
                        <p>{`Showing ${page * pageSize + 1}-${
                          page * pageSize + entries?.length
                        }`}</p>
                        <div className="flex gap-6 items-center">
                          <button
                            disabled={page <= 0 || loading}
                            onClick={() => setPage(page - 1)}
                            className="text-stxt text-lg disabled:opacity-20"
                          >
                            &lt;
                          </button>
                          <button
                            disabled={page >= maxPages - 1 || loading}
                            onClick={() => setPage(page + 1)}
                            className="text-stxt text-lg disabled:opacity-20"
                          >
                            &gt;
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => {
                return (
                  <tr key={index} className="report-table-row">
                    <td className="report-table-cell">
                      {entry?.transactionId}
                    </td>
                    <td className="report-table-cell">
                      {formatDate(entry?.date)}
                    </td>
                    <td className={`report-table-cell`}>
                      {formatAmount(entry?.amount || "")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </section>
  );
});

export default TransactionHistory;
