import validateMsisdn from "../functions/validateMsisdn";

export const TELENOR_NETWORK = Object.freeze({
  networkId: 1,
  networkName: "telenor",
  msisdnPrefixes: ["9234"],
});
export const UFONE_NETWORK = Object.freeze({
  networkId: 1,
  networkName: "ufone",
  msisdnPrefixes: ["9233"],
});
export const JAZZ_NETWORK = Object.freeze({
  networkId: 1,
  networkName: "jazz",
  msisdnPrefixes: ["9230"],
});
export const ZONG_NETWORK = Object.freeze({
  networkId: 1,
  networkName: "zong",
  msisdnPrefixes: ["9231", "92370"],
});
const All_Networks = Object.freeze([
  TELENOR_NETWORK,
  UFONE_NETWORK,
  JAZZ_NETWORK,
  ZONG_NETWORK,
]);
export default All_Networks;

export const getNetwork = (msisdn) => {
  if (validateMsisdn(msisdn)) {
    return All_Networks.find(
      (network) =>
        (network.msisdnPrefixes.find((networkmsisdnPrefix) =>
          msisdn.startsWith(networkmsisdnPrefix)
        ) || null) !== null
    );
  } else {
    return null;
  }
};

export const getNetworkByName = (name) => {
  return All_Networks.find((network) => network.networkName === name);
};
