import React from "react";
import "../styles/Switch.css";

const Switch = ({ checked, onChange, disabled, loading }) => {
  return (
    <label
      disabled={disabled}
      className={`switch ${
        loading
          ? "cursor-wait"
          : disabled
          ? "cursor-not-allowed"
          : "cursor-pointer"
      } `}
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled || loading}
        onChange={onChange}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;
