const FileStatuses = Object.freeze({
  UPLOADED: {
    value: 1,
    text: "Processing",
    description: "The file has been uploaded successfully.",
    isClickable: false,
    isProcessing: true,
  },
  PREPROCESSING: {
    value: 2,
    text: "Processing",
    description: "The file is currently being processed.",
    isClickable: false,
    isProcessing: true,
  },
  VALIDATION_FAILED: {
    value: 3,
    text: "Validation Failed",
    description: "The file failed validation checks.",
    isClickable: false,
    className: "!text-cpink"
  },
  PROCESSED_AND_READY_FOR_CAMPAIGN: {
    value: 4,
    text: "Ready",
    description: "The file has been processed and is ready for campaigns.",
    isClickable: true,
    className: "!text-cgreen"
  },
  DELETED: {
    value: 5,
    text: "Deleted",
    description: "The file has been deleted.",
    isClickable: false,
  },
});

export default FileStatuses;

export const FileStatusList = Object.values(FileStatuses);

export function findFileStatus(value) {
  return (
    FileStatusList.find((transcation) => transcation.value === value) || {
      value: value,
      text: "Unknown",
      className: "",
    }
  );
}
