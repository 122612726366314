import React, { useEffect, useMemo, useState } from "react";
import "../../styles/reports.css";
import useGetReports from "../../hooks/reports/useGetReports";
import FilterObjs from "../../constants/Reports/ReportsFilters";
import Loader from "../../reusable/Loader";
import DateInput from "../../reusable/DateInput";
import Dropdown from "../../reusable/Dropdown";
import InputNumber from "../../reusable/InputNumber";
import { Link, useSearchParams } from "react-router-dom";
import { encryptString } from "../../functions/Encryption";
// import backIcon from "../../assets/svgs/back-icon.svg";
// import backIconDisabled from "../../assets/svgs/back-icon-disabled.svg";
// import nextIcon from "../../assets/svgs/next-icon.svg";
// import nextIconDisabled from "../../assets/svgs/next-icon-disabled.svg";
import PATHS, {
  REPORT_DETAIL_ROUTE_REPORT_ID_KEY_IN_PATH,
} from "../../constants/RoutePaths";
import TextWithLoader from "../../reusable/TextWithLoader";

const ReportsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const todayDate = useMemo(() => new Date().toISOString().split("T")[0], []);
  const {
    reportsFormatted,
    reportsCount,
    fetchingFirst: loading,
    setFilter: updateFilter,
  } = useGetReports();

  const defaultFilter = {
    fromDate: null,
    toDate: null,
    filterType: "",
    transactionId: "",
  };
  const [filter, setFilter] = useState(defaultFilter);

  // Retrieve the 'p' parameter
  const page = +searchParams.get("p") || null;
  const setPage = (value) => setSearchParams({ p: value });
  const pageSize = 7;
  const maxPages = Math.ceil(reportsCount?.count / pageSize);

  // Set 'p' to 0 if it is null
  useEffect(() => {
    if (!page || page < 1) {
      setSearchParams({ p: "1" });
    }
  }, [page, setSearchParams]);

  useEffect(() => {
    if (page >= 1) {
      updateFilter({ ...filter }, page, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleFilterChange = (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    const { name, value, type } = event.target;
    if (name === "transactionId") {
      setFilter({ ...defaultFilter, transactionId: value });
    } else {
      const formattedValue =
        type === "date" ? (value.length > 0 ? value : null) : value;
      setFilter((values) => ({
        ...values,
        [name]: formattedValue,
        transactionId: "",
      }));
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (page === 1) {
      updateFilter(filter, page, pageSize);
    } else {
      setPage(1);
    }
  };

  return (
    <section className="space-y-5 flex flex-col h-full">
      <section className="heading-text">My Reports</section>
      <section className={`relative bg-sbg flex-grow overflow-hidden`}>
        {loading ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <section
          className={`${
            loading ? "opacity-0 pointer-events-none" : ""
          } flex flex-col h-full`}
        >
          <div className="px-8 py-6">
            <form
              onSubmit={handleSearchSubmit}
              className="flex justify-between items-center gap-6"
            >
              <div className="text-2xl">Search</div>
              <div className="flex-grow flex justify-between items-center flex-wrap gap-5">
                <div className="flex-grow">
                  <DateInput
                    name={"fromDate"}
                    value={filter?.fromDate}
                    onChange={handleFilterChange}
                    placeholder={"From"}
                    max={filter?.toDate || todayDate}
                  />
                </div>
                <div className="flex-grow">
                  <DateInput
                    name={"toDate"}
                    value={filter?.toDate}
                    onChange={handleFilterChange}
                    placeholder={"To"}
                    min={filter?.fromDate || null}
                    max={todayDate}
                  />
                </div>
                <div className="flex-grow">
                  <Dropdown
                    className={"w-full"}
                    name="filterType"
                    value={filter?.filterType}
                    onChange={handleFilterChange}
                    options={FilterObjs}
                    placeholder={"Filters"}
                  />
                </div>
                <div className="flex-grow">
                  <InputNumber
                    name="transactionId"
                    id="transactionIdSearch"
                    value={filter?.transactionId}
                    onChange={handleFilterChange}
                    placeholder="Search by transaction id"
                    maxLength={10}
                  />
                </div>
              </div>
              <div>
                <button className="btn">Search</button>
              </div>
            </form>
          </div>
          <div className="px-8 pb-6 flex-grow overflow-y-auto">
            <table className="min-w-full table-auto text-left">
              <thead>
                <tr className="bg-tbg">
                  <th className="report-table-header">Transaction ID</th>
                  <th className="report-table-header">Amount</th>
                  <th className="report-table-header">Date</th>
                  <th className="report-table-header">Type</th>
                  <th className="report-table-header">Status</th>
                  <th className="report-table-header !text-stxt !font-light text-[0.8125em]">
                    {maxPages > 0 && (
                      <div>
                        <div className="flex justify-end items-center gap-6">
                          <p>{`Showing ${(page - 1) * pageSize + 1}-${
                            (page - 1) * pageSize + reportsFormatted.length
                          }`}</p>
                          <button
                            disabled={page <= 1}
                            onClick={() => setPage(page - 1)}
                            className="text-stxt text-lg disabled:opacity-20"
                          >
                            &lt;
                            {/* <img
                              className="sixe-[0.875em]"
                              src={page <= 1 ? backIconDisabled : backIcon}
                              alt="<"
                            /> */}
                          </button>
                          <button
                            disabled={page >= maxPages}
                            onClick={() => setPage(page + 1)}
                            className="text-stxt text-lg disabled:opacity-20"
                          >
                            &gt;
                            {/* <img
                              className="sixe-[0.875em]"
                              src={
                                page >= maxPages ? nextIconDisabled : nextIcon
                              }
                              alt=">"
                            /> */}
                          </button>
                        </div>
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportsFormatted.map((report, index) => (
                  <tr key={index} className="report-table-row">
                    <td className="report-table-cell !text-ttxt">
                      {report?.hasDetails ? (
                        <Link
                          to={PATHS.REPORT_DETAIL.replace(
                            REPORT_DETAIL_ROUTE_REPORT_ID_KEY_IN_PATH,
                            encryptString(report?.transactionId)
                          )}
                          className="underline"
                        >
                          {report?.transactionId}
                        </Link>
                      ) : (
                        <p className="select-none">{report?.transactionId}</p>
                      )}
                    </td>
                    <td className="report-table-cell">{report?.amount}</td>
                    <td className="report-table-cell">{report?.date}</td>
                    <td className="report-table-cell">{report?.type?.text}</td>
                    <td
                      className={`report-table-cell ${report?.status?.className}`}
                    >
                      <TextWithLoader
                        text={report?.status?.text}
                        showLoader={report?.status?.showLoader}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="bg-tbg py-3 flex justify-center gap-14">
            {reportsCount?.count ? (
              <div className="flex gap-4">
                <div className="text-stxt text-lg">Transactions Count</div>
                <div className="text-lg font-[500]">{reportsCount?.count}</div>
              </div>
            ) : null}
            {reportsCount?.amount ? (
              <div className="flex gap-4">
                <div className="text-stxt text-lg">Transaction Amount</div>
                <div className="text-lg font-500">{reportsCount?.amount}</div>
              </div>
            ) : null}
          </div>
        </section>
      </section>
    </section>
  );
};

export default ReportsTable;
