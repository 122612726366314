// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-table-header {

    text-wrap: nowrap;

    padding-top: 1rem;

    padding-bottom: 1rem;

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    font-weight: 600
}

.report-table-row {

    border-bottom-width: 0.5px;

    border-color: var(--brdr);

    text-align: left
}

.report-table-cell {

    text-wrap: nowrap;

    padding-top: 1rem;

    padding-bottom: 1rem;

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    color: var(--stxt)
}`, "",{"version":3,"sources":["webpack://./src/styles/reports.css"],"names":[],"mappings":"AACE;;IAAA,iBAAuC;;IAAvC,iBAAuC;;IAAvC,oBAAuC;;IAAvC,qBAAuC;;IAAvC,sBAAuC;;IAAvC;AAAuC;;AAIvC;;IAAA,0BAA6C;;IAA7C,yBAA6C;;IAA7C;AAA6C;;AAI7C;;IAAA,iBAAsC;;IAAtC,iBAAsC;;IAAtC,oBAAsC;;IAAtC,qBAAsC;;IAAtC,sBAAsC;;IAAtC;AAAsC","sourcesContent":[".report-table-header {\n  @apply py-4 px-5 font-[600] text-nowrap;\n}\n\n.report-table-row {\n  @apply text-left border-b-[0.5px] border-brdr;\n}\n\n.report-table-cell {\n  @apply py-4 px-5 text-stxt text-nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
