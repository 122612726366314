import { useEffect, useState } from "react";
import ApiConstants from "../../../constants/ApiConstants";
import useApi from "../../useApi";

const useGetFileContent = () => {
  const { makeApiCall, loading } = useApi();
  const [fileContent, setFileContent] = useState([]);
  const [fileInfo, setFileInfo] = useState([]);
  const [filter, setFilterState] = useState(null);

  const setFilter = (fileId, page, size) => {
    setFilterState({
      fileId: fileId,
      page: page,
      size: size,
    });
  };

  useEffect(() => {
    if (filter) {
      fetchFileContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchFileContent = async () => {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_GET_FILE_CONTENT}`;
    const params = {
      fileId: filter.fileId,
      page: filter.page - 1,
      size: filter.size,
    };

    try {
      const response = await makeApiCall({
        url,
        params,
      });
      const { data } = response.data;
      if (Array.isArray(data?.content)) {
        setFileContent(data?.content);
      }
      setFileInfo({
        totalPages: data?.totalPages,
        totalElements: data?.totalElements,
        size: data?.size,
        pageNumber: data?.pageNumber,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    fileContent,
    fileInfo,
    filter,
    setFilter,
    fetching: loading,
  };
};

export default useGetFileContent;
