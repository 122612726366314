import React from "react";
import { Outlet } from "react-router-dom";

const FullPageLayout = () => {
  return (
    <div className="h-svh ">
      <section className="px-12 py-6 overflow-y-auto custom-scrollbar">
        <Outlet />
      </section>
    </div>
  );
};

export default FullPageLayout;
