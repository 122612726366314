import React from "react";
import "../../styles/topup.css";
import SingleMsisdnTopupEnterNumber from "./SingleMsisdnTopupEnterNumber";
import MultipleMsisdnTopupEnterNumber from "./MultipleMsisdnTopupEnterNumber";
import PATHS from "../../constants/RoutePaths";
import uploadFileIcon from "../../assets/svgs/upload-file-icon.svg";
import { Link } from "react-router-dom";

const SelectTopUpType = () => {
  return (
    <section className="space-y-[1.875em]">
      <div className="heading-text">Topup</div>
      <div className="select-topup-container">
        <div className="select-topup-container-child">
          <SingleMsisdnTopupEnterNumber />
        </div>
        <div className="select-topup-container-child">
          <MultipleMsisdnTopupEnterNumber />
        </div>
        <div className="w-full">
          <UploadFileSection className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default SelectTopUpType;

const UploadFileSection = ({ className }) => {
  const points = [
    "Upload only CSV file",
    "CSV File contain only 2 columns",
    "First column contains only MSISDN",
    "Second column contains transaction amount",
  ];

  return (
    <div className={`${className} p-[1.875em] bg-sbg space-y-2`}>
      <div className="w-full heading-text-2">Upload File</div>
      <div className="flex w-full justify-evenly items-center">
        <div className="space-y-5 text-stxt">
          {points.map((point, index) => (
            <div key={index} className="flex items-center gap-4">
              <div className="size-[1.875em] rounded-full border-[0.67px] border-stxt grid place-items-center ">
                {index + 1}
              </div>
              <p>{point}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center gap-14">
          <img src={uploadFileIcon} alt="" />
          <Link to={PATHS.TOPUP_FILES} className="btn">
            <span className="px-14">Upload File</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
