import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  user: null,
  userBalance: 0,
  notifyUserByEmail: false,
  balanceThreshold: 100,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserReducer: (state, action) => {
      state.user = action.payload;
    },
    updateUserBalanceReducer: (state, action) => {
      state.userBalance = action.payload;
    },
    updateNotifyLowBalanceByEmailReducer: (state, action) => {
      state.notifyUserByEmail = action.payload;
    },
    updateBalanceThresholdReducer: (state, action) => {
      state.balanceThreshold = action.payload;
    },
    resetAllReducer: (state) => {
      state.user = initialState.user;
      state.userBalance = initialState.userBalance;
      state.notifyUserByEmail = initialState.notifyUserByEmail;
      state.balanceThreshold = initialState.balanceThreshold;
    },
  },
});

const {
  saveUserReducer,
  updateUserBalanceReducer,
  updateNotifyLowBalanceByEmailReducer,
  updateBalanceThresholdReducer,
  resetAllReducer,
} = userSlice.actions;

export const useUser = () => {
  const slice = useSelector((state) => state.local.user);
  const dispatch = useDispatch();

  const saveUser = (user) => {
    dispatch(saveUserReducer(user));
  };
  const updateUserBalance = (balance) => {
    dispatch(updateUserBalanceReducer(balance));
  };
  const updateNotifyLowBalanceByEmail = (value) => {
    dispatch(updateNotifyLowBalanceByEmailReducer(value));
  };
  const updateBalanceThreshold = (value) => {
    dispatch(updateBalanceThresholdReducer(value));
  };
  const resetAll = () => {
    dispatch(resetAllReducer());
  };

  return {
    user: slice.user,
    userBalance: slice.userBalance,
    notifyUserByEmail: slice.notifyUserByEmail,
    notifyUserByEmailBalanceThreshold: slice.balanceThreshold,
    saveUser,
    updateUserBalance,
    updateNotifyLowBalanceByEmail,
    updateBalanceThreshold,
    resetAll,
  };
};

export default userSlice.reducer;
