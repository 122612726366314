import { useEffect, useState } from "react";
import { useUser } from "../features/UserSlice";
import ApiConstants from "../constants/ApiConstants";
import useApi from "./useApi";

const useGetAccountDetails = (fetchOnLoad = true) => {
  const [todaySalesStats, setTodaySalesStats] = useState({
    totalSales: 0,
    averageTransaction: 0,
    successfulTransactions: 0,
  });
  const { updateUserBalance } = useUser();
  const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_GET_ACCOUNT_DETAILS;

  const { makeApiCall, loading } = useApi();

  const getAccountDetails = async () => {
    try {
      const response = await makeApiCall({
        url,
      });
      const { data } = response?.data;
      setTodaySalesStats({
        totalSales: data?.totalSales,
        averageTransaction: data?.averageTransactions,
        successfulTransactions: data?.successfulTransactionCounts,
      });
      updateUserBalance(data?.totalBalance);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (fetchOnLoad) {
      getAccountDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { getAccountDetails, todaySalesStats, loading };
};

export default useGetAccountDetails;
