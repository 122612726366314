import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/AppState";
import { DeviceContextProvider } from "./context/DeviceState";
import { ThemeContextProvider } from "./context/ThemeState";
import EnvConstants from "./constants/EnvConstants";

const root = ReactDOM.createRoot(document.getElementById("root"));

const RootElement = () => (
  <BrowserRouter basename={EnvConstants.APP_SLUG}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DeviceContextProvider>
          <ThemeContextProvider>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </ThemeContextProvider>
        </DeviceContextProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

root.render(
  EnvConstants.REACT_STRICT_MODE_ENABLED ? (
    <React.StrictMode>
      <RootElement />
    </React.StrictMode>
  ) : (
    <RootElement />
  )
);
