import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "./UserSlice";

const initialState = {
  token: null,
  firebaseUser: null,
  networkRules: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveTokenReducer: (state, action) => {
      state.token = action.payload;
    },
    setFirebaseUserReducer: (state, action) => {
      state.firebaseUser = action.payload;
    },
    setNetworkRulesReducer: (state, action) => {
      state.networkRules = action.payload;
    },
    logOutReducer: (state) => {
      state.token = initialState.token;
      state.firebaseUser = initialState.firebaseUser;
      state.networkRules = initialState.networkRules;
    },
  },
});

const {
  saveTokenReducer,
  setFirebaseUserReducer,
  setNetworkRulesReducer,
  logOutReducer,
} = authSlice.actions;

export const useAuth = () => {
  const { resetAll: resetAllUser } = useUser();
  const slice = useSelector((state) => state.local.auth);
  const dispatch = useDispatch();

  const isAuthenticated = useMemo(() => {
    const value = slice?.token !== null;
    return value;
  }, [slice.token]);

  const token = slice.token;
  const saveToken = (token) =>
    dispatch(saveTokenReducer(JSON.parse(JSON.stringify(token))));

  const firebaseUser = slice.firebaseUser;
  const setFirebaseUser = (user) => {
    dispatch(setFirebaseUserReducer(JSON.parse(JSON.stringify(user))));
  };

  const networkRules = slice.networkRules;
  const setNetworkRules = (rules) => {
    if (rules && Array.isArray(rules)) {
      dispatch(setNetworkRulesReducer(rules));
    }
  };

  const getNetworkRuleByName = (operatorName) => {
    return networkRules.find((network) => network.operator === operatorName);
  };

  const logoutUser = () => {
    dispatch(logOutReducer());
    resetAllUser();
  };
  return {
    isAuthenticated,
    token,
    saveToken,
    firebaseUser,
    setFirebaseUser,
    networkRules,
    setNetworkRules,
    getNetworkRuleByName,
    logoutUser,
  };
};

export default authSlice.reducer;
