const ReportGenerationStatuses = Object.freeze({
  NOT_GENERATED: {
    value: 1,
    text: "Not Generated",
    description: "The transaction report has not been generated yet.",
  },
  GENERATING: {
    value: 2,
    text: "Generating",
    description: "The transaction report is being generated.",
  },
  GENERATED: {
    value: 3,
    text: "Generated",
    description: "The transaction report has been successfully generated.",
    isDownloadable: true,
  },
  FAILED_TO_GENERATE: {
    value: 4,
    text: "Failed To Generate",
    description: "The transaction report generation failed.",
  },
});

export default ReportGenerationStatuses;

const AllReportGenerationStatuses = Object.values(ReportGenerationStatuses);

export function findReportGenerationStatus(value) {
  return (
    AllReportGenerationStatuses.find((report) => report.value === value) || {
      value: value,
      text: "Unknown",
      className: "",
    }
  );
}
