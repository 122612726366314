import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";

const useDownloadReport = (reportId) => {
  const { makeApiCall, loading } = useApi();

  const downloadReport = async () => {
    const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_DOWNLOAD_REPORT;

    try {
      const response = await makeApiCall({
        url,
        params: {
          transaction_id: reportId,
        },
        responseType: "blob", // Ensures binary response for file download
      });

      // Create a blob object from the response data
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // Generate a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Report-Transaction-Id-${reportId}.csv`; // Customize the file name if needed
      link.click();

      // Clean up the object URL
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      alert("Error downloading the report. Please try again later.");
      console.error("Error downloading the report:", error);
    }
  };

  return { downloadReport, loading };
};

export default useDownloadReport;
