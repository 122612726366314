import ApiConstants from "../../../constants/ApiConstants";
import useApi from "../../useApi";

const useMakeCampaign = () => {
  const { makeApiCall } = useApi();
  const makeCampaign = async (fileId) => {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_CREATE_CAMPAIGN}`;
    const params = {
      fileId: fileId,
    };
    try {
      const response = await makeApiCall({ url, params, method: "POST" });
      return {
        status: response?.data?.status,
        message: response?.data?.message,
      };
    } catch (error) {
      console.error(error);
      return { status: false, message: error.message };
    }
  };
  return { makeCampaign };
};

export default useMakeCampaign;
