import React from "react";

const Dropdown = ({ className, value, onChange, placeholder, options }) => {
  return (
    <div className={`${className} px-[0.875em] bg-pbg`}>
      <select
        name="filterType"
        value={value || ""}
        onChange={onChange}
        className="input-filter w-full"
      >
        <option className="text-stxt" value="" >
          {placeholder}
        </option>
        {options &&
          Array.isArray(options) &&
          options.map((option) => (
            <option className="" key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Dropdown;
