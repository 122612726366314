import React from "react";
import useApp from "../context/AppState";

const NotificationPopup = ({
  onBtnClick = () => {},
  Icon,
  iconUrl,
  title = "Notification",
  description,
  btnText = "Close",
}) => {
  if (typeof onBtnClick !== "function") {
    throw new Error("onBtnClick should be a function");
  }
  const { closePopup } = useApp();
  const handleLogout = () => {
    onBtnClick();
    closePopup();
  };
  return (
    <div className="bg-sbg rounded-md p-5">
      <div className="px-20 pb-5 flex flex-col w-full items-center">
        <div className="flex flex-col w-full items-center gap-10">
          {Icon && <Icon className="size-[4.375em]" />}
          {iconUrl && <img src={iconUrl} className="size-[4.375em]" alt="" />}
          <div className="text-center space-y-3">
            {title && <p className="font-medium text-2xl">{title}</p>}
            {description && (
              <p className="font-light text-lg text-stxt">{description}</p>
            )}
          </div>
          <button onClick={handleLogout} className="btn">
            <span className="px-3 select-none">{btnText}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
