import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PATHS from "./constants/RoutePaths";
import Home from "./pages/Home";
import DashBoard from "./components/DashBoard";
import TopUp from "./components/TopUp";
import MultipleMsisdnAmountForm from "./components/TopUp/MultipleMsisdnAmountForm";
import TopUpFiles from "./components/TopUp/Files";
import ViewFile from "./components/TopUp/ViewFile";
import Wallet from "./components/Wallet";
import Reports from "./components/Reports";
import TransactionDetail from "./components/Reports/TransactionDetail";
import Profile from "./components/Profile";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import TermsOfUser from "./components/TermsOfUser";
import NotFound from "./pages/NotFound";
import "./styles/customScrollBar.css";
import FullPageLayout from "./pages/FullPageLayout";

const App = () => {
  return (
    <main className="h-svh overflow-y-auto bg-pbg">
      <Routes>
        <Route path={PATHS.SIGN_IN} element={<Login />} />
        <Route path={PATHS.SIGN_UP} element={<Signup />} />
        <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={PATHS.BASE} element={<ProtectedRoute />}>
          <Route path={PATHS.BASE} element={<Home />}>
            <Route path={PATHS.BASE} element={<DashBoard />} />
            <Route path={PATHS.DASHBOARD} element={<DashBoard />} />
            <Route path={PATHS.TOPUP} element={<TopUp />} />
            <Route path={PATHS.TOPUP_FILES_VIEW_FILE} element={<ViewFile />} />
            <Route path={PATHS.PROFILE} element={<Profile />} />
            <Route path={PATHS.TOPUP_MULTIPLE_MSISDN_ENTER_AMOUNT} element={<MultipleMsisdnAmountForm />} />
            <Route path={PATHS.TOPUP_FILES} element={<TopUpFiles />} />
            <Route path={PATHS.WALLET} element={<Wallet />} />
            <Route path={PATHS.REPORTS} element={<Reports />} />
            <Route path={PATHS.REPORT_DETAIL} element={<TransactionDetail />} />
            <Route path={PATHS.USER_TERMS} element={<TermsOfUser />} />
            <Route path={PATHS.PRIVACY_POLICY} element={<NotFound />} />
          </Route>
          {/* <Route
            path={PATHS.NO_MATCH}
            element={<Navigate to={PATHS.DASHBOARD} replace />}
          /> */}
          <Route path={PATHS.NO_MATCH} element={<NotFound />} />
        </Route>
        <Route path={PATHS.PAGE_LAYOUT} element={<FullPageLayout />}>
          <Route path={PATHS.USER_TERMS_PAGE} element={<TermsOfUser />} />
          <Route path={PATHS.PRIVACY_POLICY_PAGE} element={<NotFound />} />
        </Route>
        <Route
          path={PATHS.NO_MATCH}
          element={<Navigate to={PATHS.SIGN_IN} replace />}
        />
      </Routes>
    </main>
  );
};

export default App;
