import React, { useEffect, useMemo, useRef, useState } from "react";
import Networks, { getNetwork, getNetworkByName } from "../../data/Networks";
import pakistanFlag from "../../assets/images/pakistan-flag.png";
import validateMsisdn from "../../functions/validateMsisdn";
import useApp from "../../context/AppState";
import { toTitleCase } from "../../functions/stringFunctions";
import PaymentSentPopup from "../../reusable/PaymentSentPopup";
import PATHS from "../../constants/RoutePaths";
import { useNavigate } from "react-router-dom";
import PaymentSendingPopup from "../../reusable/PaymentSendingPopup";
import { useAuth } from "../../features/AuthSlice";
import useDoQuickTopUp from "../../hooks/topup/useDoQuickTopUp";
import useGetAccountDetails from "../../hooks/useGetAccountDetails";
import { useUser } from "../../features/UserSlice";
import PaymentFailedPopUp from "../../reusable/PaymentFailedPopUp";

const SingleMsisdnTopupEnterNumber = () => {
  const [number, setNumber] = useState("");
  const [mesage, setMesage] = useState(null);
  const { showPopup } = useApp();

  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    if (!isNaN(value)) {
      setNumber(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setMesage(null);
    if (number.length !== 10) {
      setMesage("Please provide a valid number");
      return;
    }
    const msisdn = `92${number}`;
    if (!validateMsisdn(msisdn)) {
      setMesage("Invalid Mobile Number");
      return;
    } else if (!getNetwork(msisdn)) {
      setMesage("Network not found");
      return;
    } else {
      showPopup(<PopUp msisdn={msisdn} />);
    }
  };
  return (
    <form onSubmit={handleSubmit} id="single-msisdn-enter-number-section">
      <div className="w-full heading-text-2">Single Mobile Number</div>
      <div className="mx-5 space-y-6">
        <MsisdnInput value={number} onChange={handleChange} />
        <div className="h-12 text-center">
          <p className="text-cpink">{mesage}</p>
        </div>
      </div>
      <div className="mx-5 ">
        <button disabled={number.length !== 10} type="submit" className="btn">
          <span className="px-14">Next</span>
        </button>
      </div>
    </form>
  );
};

const MsisdnInput = ({ value, onChange, ...props }) => {
  return (
    <div className="flex gap-1">
      <div className="bg-pbg rounded-s-md py-[1.125em] px-5 flex gap-1">
        <img className="size-[22px]" src={pakistanFlag} alt="" />
        <p className="text-stxt">+92</p>
      </div>
      <div className="flex-grow">
        <input
          type="tel"
          value={value}
          onChange={onChange}
          maxLength={10}
          className="bg-pbg py-[1.125em] px-5 focus:outline-none w-full rounded-e-md disabled:text-stxt"
          {...props}
        />
      </div>
    </div>
  );
};

const PopUp = ({ msisdn }) => {
  const steps = {
    ENTER_AMOUNT: 0,
    CONFIRM_TRANSACTION: 1,
    SHOW_SUCCESS_RESULT: 2,
    SHOW_FAILED_RESULT: 3,
  };

  const detectedNetwork = useMemo(() => getNetwork(msisdn), [msisdn]);
  const { closePopup } = useApp();
  const { getNetworkRuleByName } = useAuth();
  const { getAccountDetails } = useGetAccountDetails();
  const { userBalance } = useUser();
  const { doQuickTopUpSingleMsisdn, formatRequestData } = useDoQuickTopUp();
  const navigate = useNavigate();
  const [network, setNetwork] = useState(detectedNetwork);
  const minAmount = useMemo(() => {
    const networkRules = getNetworkRuleByName(network?.networkName);
    return networkRules?.minCreditLimit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);
  const maxAmount = useMemo(() => {
    const networkRules = getNetworkRuleByName(network?.networkName);
    return networkRules?.maxCreditLimit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  useEffect(() => {
    getAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [amount, setAmount] = useState(minAmount);
  const [step, setStep] = useState(steps.ENTER_AMOUNT);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const networkRef = useRef(null);
  const handleNetworkChange = (e) => {
    e.preventDefault();
    networkRef.current.size = 1;
    const network = getNetworkByName(e.target.value);
    setNetwork(network);
  };
  const handleCheckOperator = () => {
    networkRef.current.focus();
    networkRef.current.size = networkRef.current.options.length;
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setAmount(Number(value));
    }
  };

  const formatAmount = (val) => (val ? `Rs ${val.toLocaleString()}` : "");

  const doNotHasEnoughBalance = userBalance < amount;

  const handleSend = (e) => {
    e.preventDefault();
    setStep(steps.CONFIRM_TRANSACTION);
  };

  const handleConfirm = async () => {
    setLoading(true);
    const result = await doQuickTopUpSingleMsisdn(
      formatRequestData(msisdn, network?.networkName, amount)
    );
    setLoading(false);
    if (result.status) {
      setStep(steps.SHOW_SUCCESS_RESULT);
      getAccountDetails();
    } else {
      setStep(steps.SHOW_FAILED_RESULT);
      setErrorMessage(result.message);
    }
  };

  const paymentSentOnCancel = () => {
    closePopup();
    navigate(PATHS.TOPUP);
  };
  const paymentSentOnViewReceipt = () => {
    closePopup();
    navigate(PATHS.REPORTS);
  };

  return step === steps.ENTER_AMOUNT ? (
    <form
      onSubmit={handleSend}
      className="px-16 py-9 bg-sbg rounded flex flex-col items-center gap-12"
    >
      <p className="text-center text-3xl font-light">Mobile Top-Up</p>
      <MsisdnInput value={msisdn} disabled />
      <div className="flex w-full justify-between items-center">
        <select
          ref={networkRef}
          className="text-[1.375em] leading-5 text-ttxt font-semibold bg-sbg cursor-pointer focus:outline-none overflow-y-visible"
          value={network?.networkName}
          onChange={handleNetworkChange}
        >
          {Networks.map((network, index) => (
            <option key={index} value={network?.networkName}>
              {toTitleCase(network?.networkName)}
            </option>
          ))}
        </select>
        {/* <p className="text-[1.375em] leading-5 text-ttxt font-semibold">
          {network?.networkName}
        </p> */}
        <div>
          <p className="font-light">{`We Detected ${toTitleCase(
            detectedNetwork?.networkName
          )} Pakistan`}</p>
          <div className="flex justify-end">
            <div
              className="text-sm text-ttxt underline cursor-pointer"
              onClick={handleCheckOperator}
            >
              Check Operators
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-3">
        <p className="text-lg font-medium">Add amount</p>
        <div className="space-y-2.5">
          <input
            type="number"
            className="bg-pbg text-lg py-4 px-5 focus:outline-none w-[20vw] rounded-e-md disabled:text-stxt"
            value={amount}
            onChange={handleAmountChange}
            required
            min={minAmount}
            max={maxAmount}
          />
          <p className="font-light text-sm">{`Amount has to be between ${formatAmount(
            minAmount
          )} and ${formatAmount(maxAmount)}`}</p>
        </div>
      </div>
      {doNotHasEnoughBalance && (
        <div className="flex justify-center text-center">
          <p className="text-cyellow underline">Not Enough Balance</p>
        </div>
      )}
      <div className="flex gap-12">
        <div className="btn-secondary" onClick={closePopup}>
          <p className="px-4">Back</p>
        </div>
        <button disabled={doNotHasEnoughBalance} className="btn" type="submit">
          <p className="px-4">Send</p>
        </button>
      </div>
    </form>
  ) : step === steps.CONFIRM_TRANSACTION ? (
    loading ? (
      <PaymentSendingPopup />
    ) : (
      <div className="px-16 py-9 bg-sbg text-center rounded space-y-16">
        <p className="text-3xl font-light">Confirmation</p>
        <div className="space-y-5">
          <div className="font-light">
            You are about to send airtime to:&nbsp;
            <span className="font-medium">+{msisdn}</span>
          </div>
          <div className="text-ttxt text-[22px] leading-5 font-semibold">
            {toTitleCase(network?.networkName)}
          </div>
          <div className="font-light text-lg">
            Amount: Rs <span className="font-medium">{amount}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 w-[30vw]">
          <div>
            <button
              disabled={loading}
              className="btn-secondary w-full"
              onClick={closePopup}
            >
              Cancel
            </button>
          </div>
          <div className="bg-gray-600">
            <button
              disabled={loading || doNotHasEnoughBalance}
              className="btn w-full"
              onClick={handleConfirm}
            >
              {loading ? "Loading..." : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    )
  ) : step === steps.SHOW_SUCCESS_RESULT ? (
    <PaymentSentPopup
      onCancel={paymentSentOnCancel}
      onViewReceipt={paymentSentOnViewReceipt}
    />
  ) : (
    <PaymentFailedPopUp onCancel={closePopup} errorMessage={errorMessage} />
  );
};

export default SingleMsisdnTopupEnterNumber;
