import { useEffect, useState } from "react";
import ApiConstants from "../../constants/ApiConstants";
import ReportGenerationStatuses, {
  findReportGenerationStatus,
} from "../../constants/Reports/ReportGenerationStatuses";
import useApi from "../useApi";

const useGetReportStatus = (reportId) => {
  const [reportStatus, setReportStatus] = useState(null);

  const { makeApiCall, loading } = useApi();
  const getReportStatus = async () => {
    const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_GET_REPORT_STATUS;
    try {
      const response = await makeApiCall({
        url,
        params: {
          transaction_id: reportId,
        },
      });
      console.table(response?.data);
      const { data } = response?.data;
      setReportStatus(
        findReportGenerationStatus(data?.transactionReportStatusCode)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReportStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!reportStatus) {
      return;
    }
    if (reportStatus.value === ReportGenerationStatuses.GENERATING.value) {
      const interval = setTimeout(() => {
        getReportStatus();
      }, 3000);
      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportStatus]);

  return { reportStatus, loading };
};

export default useGetReportStatus;
