export const REPORT_TYPES = Object.freeze({
  SINGLE: {
    value: 1,
    text: "Single Msisdn",
    description: "A single top-up request.",
  },
  MULTIPLE: {
    value: 2,
    text: "Multiple Msisdn",
    description: "A request with multiple top-ups.",
  },
  CAMPAIGN: {
    value: 3,
    text: "List",
    description: "A campaign request involving multiple top-ups.",
  },
});

const ALL_REPORTS = Object.values(REPORT_TYPES);

export default ALL_REPORTS;

export function findReportType(value) {
  return (
    ALL_REPORTS.find((report) => String(report.value) === String(value)) || {
      value: "Unknown",
      text: "Unknown",
    }
  );
}
