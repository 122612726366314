const TermsOfUser = Object.freeze([
  <>Minimum balance top up will be subject to the Telco’s policies.</>,
  <>
    <b>Services:</b> We offer a balance top up service on the 4 Telecom
    operators in Pakistan; Jazz, Zong, Telenor, Ufone.
  </>,
  <>
    Changes. We strive to continually improve our Services and offerings. You
    acknowledge that we may change our APIs from time to time, and that it is
    your responsibility to ensure that your use of our Services is compatible
    with then-current APIs for the Service. While we endeavor to avoid changes
    to our APIs that are not backward compatible, in the event that such a
    change is implemented we will use reasonable efforts to inform you of any
    material changes with notice whenever possible so that you can adjust your
    applications.
  </>,
  <>
    <b>Changes</b>: We are committed to continuously improving our services and
    offerings. You acknowledge that our APIs may be updated periodically, and it
    is your responsibility to ensure that your use of our services remains
    compatible with the current versions of the APIs. While we strive to
    maintain backward compatibility, there may be instances where non-compatible
    changes are necessary. In such cases, we will make reasonable efforts to
    notify you of any significant changes in advance, whenever feasible, to
    allow you sufficient time to adapt your applications accordingly.
  </>,
  <>
    To access certain Services, you must create an account (an "Account") on our
    site. Ensure the information provided is accurate and updated. You are
    responsible for all activity on your Account and for keeping your password
    secure. We are not liable for any losses caused by unauthorized use of your
    Account. Do not use another person's account, registration details, or
    password without permission. Notify us immediately of any changes to your
    eligibility, security breaches, or unauthorized access. Avoid sharing or
    posting your login information. To delete your Account, email&nbsp;
    <a
      href="mailto:topup.support@planetbeyond.co.uk"
      target="_blank"
      rel="noreferrer"
    >
      topup.support@planetbeyond.co.uk
    </a>
    .
  </>,
  <>
    We reserve the right to impose a fixed fee on transactions against any
    operators balance top up. The fees may vary based on the MNO.
  </>,
  <>
    We take no responsibility of the activity carried out through a customer’s
    account; any unauthorized access is to be dealt with by the customer. We
    will ensure industry best security provision for the customer accounts.
  </>,
  <>
    These Terms of Use will remain in effect for the entire duration of your use
    of the Services.
  </>,
  <>
    You may terminate your account by sending an email to&nbsp;
    <a
      href="mailto:topup.support@planetbeyond.co.uk"
      target="_blank"
      rel="noreferrer"
    >
      topup.support@planetbeyond.co.uk
    </a>
    .
  </>,
]);

export default TermsOfUser;
