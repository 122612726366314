import ApiConstants from "../../../constants/ApiConstants";
import useApi from "../../useApi";

const useInitSession = () => {
  const { makeApiCall } = useApi();
  return async (orderAmount) => {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_WALLET_INIT_SESSION}`;
    try {
      const response = await makeApiCall({
        url,
        method: "POST",
        data: {
          orderAmount,
        },
      });
      const { data } = response?.data;
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
};

export default useInitSession;
