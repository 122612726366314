import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ children, disabled, ...props }) => {
  const preventDefault = (event) => event.preventDefault();

  return disabled ? (
    <span
      {...props}
      className={`!cursor-not-allowed hover:no-underline ${props.className}`}
      onClick={preventDefault}
    >
      {children}
    </span>
  ) : (
    <Link {...props}>{children}</Link>
  );
};

export default CustomLink;
