export function encryptString(str) {
  return btoa(encodeURIComponent(str));
}
export function decryptString(str) {
  try {
    // Attempt to decode the input
    return atob(str);
  } catch (error) {
    // Handle the error if the input is not a valid base64 string
    console.error("Invalid base64 string:", error?.message);
    return null; // or handle accordingly (e.g., show a message or throw a custom error)
  }
}
