import React from "react";
import { ReactComponent as BackArrow } from "../../assets/svgs/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import TermsOfUserData from "../../data/TermsOfUser";

const TermsOfUser = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <section className="space-y-5 flex flex-col h-full">
      <div className="flex gap-6 items-center">
        <button onClick={handleGoBack}>
          <BackArrow className="size-[1.625em] hover:scale-105" />
        </button>
        <p className="heading-text">{`Terms & Conditions`}</p>
      </div>
      <section className="bg-sbg flex-grow overflow-hidden">
        <section className="overflow-y-auto h-full px-12 pt-9 pb-24 rounded-md">
          <h1 className="text-center text-2xl font-medium">Terms & Conditions</h1>
          <div className="mt-12">
            <ul
              id="terms-of-user-list"
              className="list-disc text-stxt text-xl space-y-2"
            >
              {TermsOfUserData.map((data, index) => (
                <li key={index}>{data}</li>
              ))}
            </ul>
          </div>
        </section>
      </section>
    </section>
  );
};

export default TermsOfUser;
