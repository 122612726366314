export const Filters = Object.freeze({
  NO_FILTER: 0,
  PROCESSED_TRANSACTIONS: 1,
  SINGLE_MSISDN_TYPE_TRANSACTIONS: 2,
  MULTIPLE_TYPE_TRANSACTIONS: 3,
  LIST_TYPE_TRANSACTIONS: 4,
});

const ReportsFilters = [
  // { text: "Processed", value: Filters.PROCESSED_TRANSACTIONS },
  { text: "Single Msisdn", value: Filters.SINGLE_MSISDN_TYPE_TRANSACTIONS },
  { text: "Multiple Msisdns", value: Filters.MULTIPLE_TYPE_TRANSACTIONS },
  { text: "List", value: Filters.LIST_TYPE_TRANSACTIONS },
];

export default ReportsFilters;
