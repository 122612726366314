const useHandlePage = (state, setState) => {
  const page = state.page;
  const setPage = (newPage) => {
    setState((prev) => ({ ...prev, page: newPage }));
  };
  const pageSize = state.size;
  const setPageSize = (newSize) => {
    setState((prev) => ({ ...prev, size: newSize }));
  };
  return { page, setPage, pageSize, setPageSize };
};

export default useHandlePage;
