import React from "react";
import SuccessPopUp from "./SuccessPopUp";

const PaymentSentPopup = ({ onCancel, onViewReceipt }) => {
  return (
    <SuccessPopUp
      onCancel={onCancel}
      onMainBtnClick={onViewReceipt}
      title="Payment Sent"
      description="All payments have been sent to corresponding msisdns"
      mainBtnText="View Receipt"
    />
  );
};

export default PaymentSentPopup;
