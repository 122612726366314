import ApiConstants from "../../../constants/ApiConstants";
import useApi from "../../useApi";

const useDeleteFile = () => {
  const { makeApiCall, loading } = useApi();
  const deleteFile = async (id) => {
    const url = `${
      ApiConstants.BASE_URL
    }${ApiConstants.ENDPOINT_DELETE_FILE.replace(
      ApiConstants.FILE_ID_PATH_VARIABLE_KEY,
      id
    )}`;
    try {
      const response = await makeApiCall({
        url: url,
        method: "DELETE",
      });
      return response.data.status;
    } catch (error) {
      console.log(error);
    }
  };
  return { deleteFile, loading };
};

export default useDeleteFile;
