import React, { useContext, useEffect, useState } from "react";
import { themeContext as ThemeContext } from "./context";

export const ThemeContextProvider = ({ children }) => {
  const defaultThemeKey = "default";
  const themeKeyInLocalStorage = "theme";
  const [theme, setTheme] = useState(
    localStorage.getItem(themeKeyInLocalStorage) || defaultThemeKey
  );

  useEffect(() => {
    //Defined Themes
    const themes = [
      {
        name: defaultThemeKey,
        colors: [
          { key: "--ptxt", value: "#FFFFFF" }, // Primary Text Color
          { key: "--stxt", value: "#FFFFFF80" }, // Secondary Text Color
          { key: "--ttxt", value: "#19A6AB" }, // Tertiary Text Color
          { key: "--pbg", value: "#141B2D" }, // Primary Background Color
          { key: "--sbg", value: "#1F2940" }, // Secondary Background Color
          { key: "--tbg", value: "#2A3755" }, // Tertiary Background Color
          { key: "--btnPrimary", value: "#19A6AB" }, // Button Color
          { key: "--btnHover", value: "#137C80" }, // Button Hover Color
          { key: "--btnDisabled", value: "#1C6876" }, // Button Disabled Color
          { key: "--brdr", value: "#FFFFFF33" }, // Border Color
          { key: "--custom-yellow", value: "#FFC642" }, // Yellow Border Color
          { key: "--custom-blue", value: "#236BFE" }, // Blue Border Color
          { key: "--custom-pink", value: "#F69294" }, // Pink Border Color
          { key: "--custom-green", value: "#74AB64" }, // Green Border Color
        ],
      },
    ];

    localStorage.setItem(themeKeyInLocalStorage, theme);
    const selectedTheme =
      themes.find((themeItem) => themeItem.name === theme) ||
      themes.find((themeItem) => themeItem.name === defaultThemeKey);
    updateTheme(selectedTheme.colors);
  }, [theme]);

  const updateTheme = (colors) => {
    const root = document.documentElement;
    if (colors && Array.isArray(colors)) {
      colors.forEach(({ key, value }) => {
        if (value) {
          root.style.setProperty(key, value);
        }
      });
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export default useTheme;
