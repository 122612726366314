// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-topup-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 3rem
}

.select-topup-container-child {
  width: 33.333333%;
  min-width: 30em;
  flex-grow: 1;
  border-radius: 0.375rem;
  background-color: var(--sbg);
  padding: 1.875em;
  box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.15)
}

.file-view-upload-container {
  display: grid;
  gap: 3rem
}

@media (min-width: 1440px) {

  .file-view-upload-container {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/topup.css"],"names":[],"mappings":"AACE;EAAA,aAA0C;EAA1C,eAA0C;EAA1C,oBAA0C;EAA1C;AAA0C;;AAI1C;EAAA,iBAAiE;EAAjE,eAAiE;EAAjE,YAAiE;EAAjE,uBAAiE;EAAjE,4BAAiE;EAAjE,gBAAiE;EACjE;AADiE;;AAKjE;EAAA,aAAqC;EAArC;AAAqC;;AAArC;;EAAA;IAAA;EAAqC;AAAA","sourcesContent":[".select-topup-container {\n  @apply flex gap-12 items-stretch flex-wrap;\n}\n\n.select-topup-container-child {\n  @apply min-w-[30em] w-1/3 flex-grow bg-sbg p-[1.875em] rounded-md;\n  box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.15);\n}\n\n.file-view-upload-container {\n  @apply grid laptop:grid-cols-2 gap-12;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
