import React from "react";
import { ReactComponent as SessionExpiredIcon } from "../assets/svgs/session-expired-icon.svg";
import { useAuth } from "../features/AuthSlice";
import NotificationPopup from "../reusable/NotificationPopup";

const SessionExpiredPopup = () => {
  const { logoutUser } = useAuth();
  return (
    <NotificationPopup
      onBtnClick={logoutUser}
      Icon={SessionExpiredIcon}
      title="Session Expired"
      description="For your security, your session has timed out. Please log in again to continue."
      btnText="Log In Again"
    />
  );
};

export default SessionExpiredPopup;
