import React from "react";
import { ReactComponent as WalletIcon } from "../assets/svgs/wallet.svg";
import { ReactComponent as MobileIcon } from "../assets/svgs/mobile.svg";
import "../styles/sending-payment.css";

const PaymentSendingPopup = () => {
  return (
    <div className="bg-sbg px-32 py-20 flex flex-col items-center gap-14 rounded-md">
      <div className="flex justify-between items-center gap-9">
        <WalletIcon />
        <SendingAnimation />
        <MobileIcon />
      </div>
      <div className="text-center space-y-4">
        <p className="font-medium text-2xl">Payment Sending</p>
        <p className="font-light text-lg text-stxt">
          Please wait, payment sending is in process
        </p>
      </div>
    </div>
  );
};

export default PaymentSendingPopup;

const SendingAnimation = () => {
  const count = 8;
  const duration = 2;
  return (
    <div className="w-20 relative">
      {Array.from({ length: count }, (_, index) => (
        <div key={index} className={`absolute w-full`}>
          <div
            className="flex justify-end floating-dot"
            style={{
              animationDelay: `${index * (duration / count)}s`,
              animationDuration: `${duration}s`,
            }}
          >
            <div className="size-[5.52px] bg-cyellow rounded-full"></div>
          </div>
        </div>
      ))}
    </div>
  );
};
