import React from "react";
import FailurePopup from "./FailurePopup";

const PaymentFailedPopUp = ({ onCancel, errorMessage }) => {
  return (
    <FailurePopup
      title={`Payment Failed${errorMessage ? ` (${errorMessage})` : ""}`}
      description="Payments could not be sent to the corresponding msisdns."
      mainBtnText="Return"
      onCancel={onCancel}
    />
  );
};

export default PaymentFailedPopUp;
