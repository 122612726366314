import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./features/AuthSlice";
import PATHS from "./constants/RoutePaths";

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Outlet /> : <Navigate to={PATHS.SIGN_IN} replace />;
};

export default ProtectedRoute;
