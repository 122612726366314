// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-small {
    --loader-small-size: 1.25em;
    width: var(--loader-small-size);
    height: var(--loader-small-size);
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
  }
  .loader-small::before , .loader-small::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: calc(var(--loader-small-size) / 12) solid #FFF;
    animation: prixClipFix 2s linear infinite ;
  }
  .loader-small::after{
    inset: calc(var(--loader-small-size) / 6);
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: #FF3D00;
  }
  
  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }
  
  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  }`, "",{"version":3,"sources":["webpack://./src/styles/loader-small.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,+BAA+B;IAC/B,gCAAgC;IAChC,kBAAkB;IAClB,kBAAkB;IAClB;EACF;EACA;IACE,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,sDAAsD;IACtD,0CAA0C;EAC5C;EACA;IACE,yCAAyC;IACzC,uCAAuC;IACvC,qBAAqB;EACvB;;EAEA;IACE,MAAM,uBAAuB;IAC7B,QAAQ,yBAAyB;EACnC;;EAEA;MACI,MAAM,8CAA8C;MACpD,MAAM,0DAA0D;MAChE,YAAY,mEAAmE;EACnF","sourcesContent":[".loader-small {\n    --loader-small-size: 1.25em;\n    width: var(--loader-small-size);\n    height: var(--loader-small-size);\n    border-radius: 50%;\n    position: relative;\n    animation: rotate 1s linear infinite\n  }\n  .loader-small::before , .loader-small::after {\n    content: \"\";\n    box-sizing: border-box;\n    position: absolute;\n    inset: 0px;\n    border-radius: 50%;\n    border: calc(var(--loader-small-size) / 12) solid #FFF;\n    animation: prixClipFix 2s linear infinite ;\n  }\n  .loader-small::after{\n    inset: calc(var(--loader-small-size) / 6);\n    transform: rotate3d(90, 90, 0, 180deg );\n    border-color: #FF3D00;\n  }\n  \n  @keyframes rotate {\n    0%   {transform: rotate(0deg)}\n    100%   {transform: rotate(360deg)}\n  }\n  \n  @keyframes prixClipFix {\n      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}\n      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}\n      75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
