import React, { useEffect, useMemo, useState } from "react";
import ProfileLabelInputComponent from "../../reusable/ProfileLabelInputComponent";
import Switch from "../../reusable/Switch";
import { useUser } from "../../features/UserSlice";
import useUpdateNotificationSettings from "../../hooks/notifcationEmail/useUpdateNotificationSettings";
import useGetNotificationSettings from "../../hooks/notifcationEmail/useGetNotificationSettings";
import useUpdateUserProfile from "../../hooks/useUpdateUserProfile";
import Constants from "../../constants/Constants";
import ProfileNotificationSettings from "../../reusable/ProfileNotificationSettings";

const Profile = () => {
  const { user, notifyUserByEmail } = useUser();
  const { getNotificationSettings, loading: fetchingNotificationSetting } =
    useGetNotificationSettings();
  const { updateNotificationSettings, loading: updatingNotificationSetting } =
    useUpdateNotificationSettings();
  const handleNotifyByEmailWhenBalanceRunsLowClick = () => {
    updateNotificationSettings(!notifyUserByEmail);
  };
  useEffect(() => {
    getNotificationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultFormData = {
    fullName: user?.fullName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };
  const { updateUserProfile, loading: updatingProfile } =
    useUpdateUserProfile();
  const [formData, setFormData] = useState(defaultFormData);
  const [updatedFormFields, setUpdatedFormFields] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const isSubmitable = useMemo(() => {
    if (updatedFormFields.length === 0) return false;
    if (updatedFormFields.includes("fullName")) {
      const passed = new RegExp(Constants.ALPHABETS_AND_SPACES_REGEX).test(
        formData?.fullName
      );
      if (!passed) return false;
    } else if (updatedFormFields.includes("phoneNumber")) {
      const passed = new RegExp(Constants.PHONE_NO_REGEX).test(
        formData?.phoneNumber
      );
      if (!passed) return false;
    }
    return updatedFormFields.every((field) => formData[field].length > 0);
  }, [formData, updatedFormFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value, type } = event.target;
    if (type === "number" || type === "tel") {
      if (!isNaN(value) && !value.includes("-")) {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setUpdatedFormFields((prevValues) => [...prevValues, name]);
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setUpdatedFormFields((prevValues) => [...prevValues, name]);
    }
  };

  const startEditMode = (e) => {
    e.preventDefault();
    setIsEditMode(true);
  };

  const cancelEditMode = (e) => {
    e.preventDefault();
    setIsEditMode(false);
    setFormData(defaultFormData);
    setUpdatedFormFields([]);
  };

  const saveUpdatedData = async (e) => {
    e.preventDefault();
    const data = {};
    updatedFormFields.forEach(
      (fieldName) => (data[fieldName] = formData?.[fieldName])
    );
    await updateUserProfile(data);
    setIsEditMode(false);
    setUpdatedFormFields([]);
  };

  return (
    <section className="space-y-10">
      <p className="font-medium text-[1.75em] leading-5">Profile</p>
      <section className="space-y-8">
        <div className="bg-sbg rounded-md px-12 py-10 space-y-12">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-medium">Personal Information</p>
            {isEditMode ? (
              <div className="flex items-center gap-6">
                <button
                  className={`btn-secondary ${
                    updatingProfile && "opacity-0 pointer-events-none"
                  }`}
                  onClick={cancelEditMode}
                >
                  Cancel
                </button>
                <button
                  className="btn"
                  disabled={!isSubmitable || updatingProfile}
                  onClick={saveUpdatedData}
                  type="submit"
                >
                  {updatingProfile ? "Saving..." : "Save"}
                </button>
              </div>
            ) : (
              <button onClick={startEditMode} className="btn">
                Edit
              </button>
            )}
          </div>
          <div>
            <ProfileLabelInputComponent
              label="Name"
              name="fullName"
              value={formData?.fullName}
              isEditMode={isEditMode}
              onChange={handleChange}
              required
              pattern={Constants.ALPHABETS_AND_SPACES_REGEX}
              errorMessage="Only alphabets and spaces are allowed"
            />
          </div>
          <div className="flex gap-24">
            <ProfileLabelInputComponent
              label="Email Address"
              name="email"
              type="email"
              value={formData?.email}
              isEditMode={isEditMode}
              isEditable={false}
            />
            {user?.phoneNumber && (
              <ProfileLabelInputComponent
                label="Phone"
                name="phoneNumber"
                value={formData?.phoneNumber}
                type="tel"
                isEditMode={isEditMode}
                onChange={handleChange}
                required
                minLength={11}
                maxLength={11}
                pattern={Constants.PHONE_NUMBER_REGEX}
                errorMessage="Invalid phone number, pattern should be 03012345678"
              />
            )}
          </div>
        </div>
        <section className="bg-sbg rounded-md px-12 py-6 flex justify-between items-center">
          <div className="space-y-1">
            <p className="text-lg font-medium">Notifications</p>
            <p className="text-sm text-stxt ">
              Turns on to be notified if your balance runs low
            </p>
          </div>
          <div className="flex gap-10 items-center">
            <ProfileNotificationSettings />
            <Switch
              checked={notifyUserByEmail}
              onChange={handleNotifyByEmailWhenBalanceRunsLowClick}
              loading={
                fetchingNotificationSetting || updatingNotificationSetting
              }
            />
          </div>
        </section>
      </section>
    </section>
  );
};

export default Profile;
