import React from "react";
import { Link } from "react-router-dom";
import PATHS from "../constants/RoutePaths";

const Footer = () => {
  return (
    <footer className="flex justify-between items-center px-[1.875em] py-[1.375em] bg-sbg border-t-[0.3px] border-t-brdr">
      <div className="flex gap-12">
        <Link className="page-link" to={PATHS.USER_TERMS}>
          Terms & Conditions
        </Link>

        <Link className="page-link" to={PATHS.FAQ}>
          FAQ
        </Link>
        {/* <Link className="page-link" to={PATHS.PRIVACY_POLICY}>Privacy Policy</Link> */}
      </div>
      <div className="text-[0.625em] tablet:text-base text-center text-stxt tablet:text-ptxt">
        2024 PlanetBeyond All right reserved.
      </div>
      <div className="hidden tablet:block"></div>
    </footer>
  );
};

export default Footer;
