// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-topup-container {

    display: flex;

    flex-wrap: wrap;

    align-items: stretch;

    gap: 3rem
}

.select-topup-container-child {

    height: 100%;

    min-width: 30em;

    flex-grow: 1
}

.file-view-upload-container {

    display: grid;

    gap: 3rem
}

@media (min-width: 1440px) {

    .file-view-upload-container {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/topup.css"],"names":[],"mappings":"AACE;;IAAA,aAA0C;;IAA1C,eAA0C;;IAA1C,oBAA0C;;IAA1C;AAA0C;;AAI1C;;IAAA,YAAoC;;IAApC,eAAoC;;IAApC;AAAoC;;AAIpC;;IAAA,aAAqC;;IAArC;AAAqC;;AAArC;;IAAA;;QAAA;IAAqC;AAAA","sourcesContent":[".select-topup-container {\n  @apply flex gap-12 items-stretch flex-wrap;\n}\n\n.select-topup-container-child {\n  @apply min-w-[30em] flex-grow h-full;\n}\n\n.file-view-upload-container {\n  @apply grid laptop:grid-cols-2 gap-12;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
