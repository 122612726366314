import React, { useId, useState } from "react";

const InputField = ({
  icon,
  placeholder,
  name,
  type,
  value = "",
  onChange,
  required = false,
  disabled = false,
  iconFocused,
  errorMessage = "Error Occured",
  pattern,
  ...props
}) => {
  const generatedId = useId();
  const id = props.id || generatedId;
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  const onFocusIcon = iconFocused || icon;
  const error = pattern && errorMessage && value && !new RegExp(pattern).test(value);

  return (
    <div className="space-y-1">
      <label
        htmlFor={id}
        className={`border-[1px] rounded-md p-4 w-full flex items-center gap-4 cursor-text ${
          isFocused ? "border-ptxt" : error ? "border-cpink" : "border-stxt"
        } `}
      >
        {icon && (
          <img className="size-5" src={isFocused ? onFocusIcon : icon} alt="" />
        )}
        <input
          className={`bg-transparent focus:outline-none flex-grow input-auto-fill`}
          id={id}
          name={name}
          type={type}
          value={value}
          disabled={disabled}
          required={required}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          error={error ? "true" : "false"}
          {...props}
        />
      </label>

      {error && <p className="text-cpink text-sm text-center ">{errorMessage}</p>}
    </div>
  );
};

export default InputField;
