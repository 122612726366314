export const BASE_ROUTE = "/";
export const SIGN_IN_ROUTE = "login";
export const SIGN_UP_ROUTE = "create-account";
export const FORGOT_PASSWORD_ROUTE = "forgot-password";
export const PROFILE_REQUIRED_ROUTE = "profile-info";
export const DASHBOARD_ROUTE = "dashbaord";
export const TOPUP_ROUTE = "top-up";
export const TOPUP_FILES_ROUTE = "files";
export const TOPUP_FILES_VIEW_FILE_ROUTE = "view";
export const TOPUP_MULTIPLE_MSISDN_ENTER_AMOUNT_ROUTE = "multiple-msisdn";
export const MY_WALLET_ROUTE = "wallet";
export const REPORTS_ROUTE = "reports";
export const REPORT_DETAIL_ROUTE = "detail";
export const REPORT_DETAIL_ROUTE_REPORT_ID_KEY = "id";
export const REPORT_DETAIL_ROUTE_REPORT_ID_KEY_IN_PATH = `:${REPORT_DETAIL_ROUTE_REPORT_ID_KEY}`;
export const PROFILE_ROUTE = "profile";
export const USER_TERMS_ROUTE = "terms-of-user";
export const PRIVACY_POLICY_ROUTE = "privacy-policy";
export const NO_MATCH_ROUTE = "*";

const PATHS = {
  NO_MATCH: NO_MATCH_ROUTE,
  BASE: BASE_ROUTE,
  SIGN_IN: "/" + SIGN_IN_ROUTE,
  SIGN_UP: "/" + SIGN_UP_ROUTE,
  PROFILE_REQUIRED: PROFILE_REQUIRED_ROUTE,
  FORGOT_PASSWORD: "/" + FORGOT_PASSWORD_ROUTE,
  DASHBOARD: BASE_ROUTE + DASHBOARD_ROUTE,
  TOPUP: BASE_ROUTE + TOPUP_ROUTE,
  TOPUP_FILES: BASE_ROUTE + TOPUP_ROUTE + "/" + TOPUP_FILES_ROUTE,
  TOPUP_FILES_VIEW_FILE: BASE_ROUTE + TOPUP_ROUTE + "/" + TOPUP_FILES_ROUTE + "/" + TOPUP_FILES_VIEW_FILE_ROUTE, 
  TOPUP_MULTIPLE_MSISDN_ENTER_AMOUNT: BASE_ROUTE + TOPUP_ROUTE + "/" + TOPUP_MULTIPLE_MSISDN_ENTER_AMOUNT_ROUTE,
  WALLET: BASE_ROUTE + MY_WALLET_ROUTE,
  REPORTS: BASE_ROUTE + REPORTS_ROUTE,
  REPORT_DETAIL: BASE_ROUTE + REPORTS_ROUTE + "/" + REPORT_DETAIL_ROUTE_REPORT_ID_KEY_IN_PATH + "/" + REPORT_DETAIL_ROUTE,
  PROFILE: BASE_ROUTE + PROFILE_ROUTE,
  USER_TERMS: "/" + USER_TERMS_ROUTE,
  PRIVACY_POLICY: "/" + PRIVACY_POLICY_ROUTE,
  PAGE_LAYOUT: "/p",
  USER_TERMS_PAGE: "/p/" + USER_TERMS_ROUTE,
  PRIVACY_POLICY_PAGE: "/p/" + PRIVACY_POLICY_ROUTE,
};

export default PATHS;
