import React, { useEffect, useRef, useState } from "react";
import useApp from "../../context/AppState";
import crossIcon from "../../assets/svgs/cross-icon-small.svg";
import { ReactComponent as RechargeWalletIconStep1 } from "../../svgs/recharge-wallet-step-1.svg";
import { ReactComponent as RechargeWalletIconStep2 } from "../../svgs/recharge-wallet-step-2.svg";
import { ReactComponent as RechargeWalletIconStep3 } from "../../svgs/recharge-wallet-step-3.svg";
import formatAmount from "../../functions/formatAmount";
import getFormattedCurrentDate from "../../functions/getFormattedCurrentDate";
import Loader from "../../reusable/Loader";
import FailurePopup from "../../reusable/FailurePopup";
import useInitSession from "../../hooks/wallet/payment_hosted_session/useInitSession";
import useInitAuth from "../../hooks/wallet/payment_hosted_session/useInitAuth";
import { useUser } from "../../features/UserSlice";

export const useRechargeWallet = (props) => {
  const { showPopup } = useApp();
  return () => showPopup(<RechargeWallet {...props} />);
};

const RechargeWallet = ({ refreshTransactionsData }) => {
  const { closePopup: closePopupFun } = useApp();
  const initSession = useInitSession();
  // eslint-disable-next-line no-unused-vars
  const initAuth = useInitAuth();
  const STEPS = Object.freeze({
    SET_PAYMENT_AMOUNT: 1,
    CARD_DETAILS: 2,
    REVIEW_DETAILS: 3,
    LOADING: 4,
    FAILED_TO_INITITATE_PAYMENT: 5,
    FAILED_TO_AUTHENTICATE_PAYMENT: 6,
    VALIDATE_AUTH: 7,
  });

  const closePopup = () => {
    closePopupFun();
    refreshTransactionsData();
  };

  const [step, setStep] = useState(STEPS.SET_PAYMENT_AMOUNT);
  const sessionIdRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const sessionId = sessionIdRef.current || null;
  const setSessionId = (id) => (sessionIdRef.current = id);

  const paymentIdRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const paymentId = paymentIdRef.current || null;
  const setPaymentId = (id) => (paymentIdRef.current = id);

  const validateAuthComponentRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const validateAuthComponent = validateAuthComponentRef.current || null;
  const setValidateAuthComponent = (data) =>
    (validateAuthComponentRef.current = data);

  const minAmountToRecharge = 5000;
  const [amountToRecharge, setAmountToRecharge] = useState(minAmountToRecharge);
  const handleAmountChange = (e) => setAmountToRecharge(e.target.value);

  const handleSubmitPayAmount = async (e) => {
    e.preventDefault();
    setStep(STEPS.LOADING);
    try {
      const initSessionResult = await initSession(amountToRecharge);

      // SESSION INFO
      setSessionId(initSessionResult.sessionId);
      setPaymentId(initSessionResult.paymentId);

      // console.log(initSessionResult)

      setStep(STEPS.CARD_DETAILS);
    } catch (error) {
      setStep(STEPS.FAILED_TO_INITITATE_PAYMENT);
    }
  };

  const handleSubmitConfirmPayment = async (e) => {
    e.preventDefault();
    try {
      setStep(STEPS.LOADING);
      const result = await initAuth(paymentId);
      console.log(result);
      setValidateAuthComponent(result);
      setStep(STEPS.VALIDATE_AUTH);
    } catch (error) {
      console.log(error);
      setStep(STEPS.FAILED_TO_AUTHENTICATE_PAYMENT);
    }
  };

  return (
    <div
      className={`bg-sbg relative ${
        step === STEPS.CARD_DETAILS
          ? "min-w-[43.75em]"
          : step === STEPS.SET_PAYMENT_AMOUNT
          ? "min-w-[54.125em]"
          : step === STEPS.REVIEW_DETAILS
          ? "min-w-[43.75em]"
          : "min-w-[43.75em]"
      } `}
    >
      <button className="absolute top-4 right-4" onClick={closePopup}>
        <img className="size-6" src={crossIcon} alt="X" />
      </button>
      <div className="flex w-full justify-center px-9 py-9 rounded-md">
        <div className="space-y-3">
          <p className="text-center text-3xl font-light">Recharge Wallet</p>
          {step === STEPS.SET_PAYMENT_AMOUNT ? (
            <StepPaymentAmount
              amountToRecharge={amountToRecharge}
              handleAmountChange={handleAmountChange}
              onCancel={closePopup}
              onSubmit={handleSubmitPayAmount}
            />
          ) : step === STEPS.CARD_DETAILS ? (
            <StepCardDetails
              onCancel={() => setStep(STEPS.SET_PAYMENT_AMOUNT)}
              sessionId={sessionId}
              onNext={() => setStep(STEPS.REVIEW_DETAILS)}
            />
          ) : step === STEPS.REVIEW_DETAILS ? (
            <StepReviewDetails
              amountToRecharge={amountToRecharge}
              onCancel={() => setStep(STEPS.SET_PAYMENT_AMOUNT)}
              onSubmit={handleSubmitConfirmPayment}
              sessionId={sessionId}
            />
          ) : step === STEPS.LOADING ? (
            <div className="grid place-items-center w-[30em] aspect-video">
              <Loader />
            </div>
          ) : step === STEPS.FAILED_TO_INITITATE_PAYMENT ? (
            <div>
              <FailurePopup
                showCross={false}
                title="Failed to Initiate Payment"
                description={"Something went wrong. Please try again."}
                mainBtnText="Retry"
                onMainBtnClick={() => setStep(STEPS.SET_PAYMENT_AMOUNT)}
              />
            </div>
          ) : step === STEPS.FAILED_TO_AUTHENTICATE_PAYMENT ? (
            <div>
              <FailurePopup
                showCross={false}
                title="Failed to Authenticate Payment"
                description={"Something went wrong. Please try again."}
                mainBtnText="Retry"
                onMainBtnClick={() => setStep(STEPS.SET_PAYMENT_AMOUNT)}
              />
            </div>
          ) : step === STEPS.VALIDATE_AUTH ? (
            <ThreedsChallenge apiResponse={validateAuthComponent} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RechargeWallet;

const StepsSection = ({ text, step = 1 }) => {
  return (
    <div className="space-y-9">
      <p className="text-center font-light text-stxt">{text}</p>
      <div className="flex gap-2 items-center justify-center">
        {[
          RechargeWalletIconStep1,
          RechargeWalletIconStep2,
          RechargeWalletIconStep3,
        ].map((Icon, index) => (
          <div key={index} className="flex gap-2 items-center">
            <div
              className={`rounded-full grid place-items-center ${
                index + 1 > step
                  ? "size-12  border-2 bg-transparent border-stxt"
                  : "size-14 border-[#19A6AB00] bg-[#19A6AB1A] "
              } `}
            >
              <Icon
                key={index}
                className={`${index + 1 > step ? "text-stxt" : "text-ttxt"}`}
              />
            </div>
            {index + 1 !== 3 && (
              <div
                className={`${index >= step ? "bg-stxt" : "bg-ttxt"} w-20 h-1`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const StepPaymentAmount = ({
  amountToRecharge,
  handleAmountChange,
  onCancel,
  onSubmit,
}) => {
  const { userBalance } = useUser();
  const minAmountToRecharge = 5000;
  return (
    <div className="space-y-11">
      <StepsSection text={"Review Details"} step={1} />
      <form
        onSubmit={onSubmit}
        className="flex flex-col items-center w-full gap-6"
      >
        <div>
          {/* TODO */}
          <p className="text-center">
            Current balance is {formatAmount(userBalance, true)}.
          </p>
          <div className="w-[29em] space-y-2.5">
            <p className="text-lg">Set Payment Amount</p>
            <div className="space-y-5">
              <p className="text-sm text-stxt">
                Enter the exact amount you want to add to your balance. The
                minimum amount is Rs {formatAmount(minAmountToRecharge, true)}.
              </p>
              <div>
                <label
                  htmlFor="amount-input"
                  className="bg-pbg px-4 py-3 flex w-full gap-2.5 rounded-sm"
                >
                  <p className="select-none text-stxt text-lg">PKR</p>
                  <input
                    id="amount-input"
                    className="bg-transparent flex-grow text-lg focus:outline-none"
                    type="number"
                    min={minAmountToRecharge}
                    max={9999999999}
                    value={amountToRecharge}
                    onChange={handleAmountChange}
                    required
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-10">
          <p className="w-[39em] text-ttxt text-center">
            Lorem ipsum dolor sit amet consectetur. Nisi adipiscing laoreet hac
            pulvinar aliquam arcu sed. Molestie ultricies faucibus sed blandit
            massa feugiat. Faucibus urna sit velit felis venenatis id lectus id.
            Sit sapien sit commodo convallis morbi sit.
          </p>
          <div className="flex justify-center items-center gap-12">
            <button onClick={onCancel} className="btn-secondary" type="button">
              <span className="px-6">Close</span>
            </button>
            <button className="btn" type="submit">
              <span className="px-6">Proceed</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const StepCardDetails = ({ sessionId, onCancel, onNext }) => {
  return (
    <div className="space-y-11">
      <StepsSection text={"Card Details"} step={2} />
      <PaymentIframe sessionId={sessionId} onPay={onNext} onCancel={onCancel} />
    </div>
  );
};

const StepReviewDetails = ({ amountToRecharge, onCancel, onSubmit }) => {
  return (
    <div className="space-y-11">
      <div className="space-y-11">
        <StepsSection text={"Review Details"} step={3} />
        <div className="flex flex-col items-center w-full gap-16">
          <div className="w-full space-y-7">
            {[
              {
                title: "Payment Details",
                items: [
                  {
                    label: "Recharge Amount ",
                    value: `${formatAmount(amountToRecharge, true)}`,
                  },
                  {
                    label: "Recharge Date",
                    value: getFormattedCurrentDate(),
                  },
                ],
              },
            ].map((section, index) => (
              <div key={index} className="space-y-5">
                <div className="text-ptxt text-lg pb-2.5 border-b-[0.5px] border-stxt">
                  {section.title}
                </div>
                <div className="space-y-3">
                  {section.items.map((item, indexJ) => (
                    <div
                      key={indexJ}
                      className="flex justify-between items-center w-full "
                    >
                      <p className="text-stxt">{item.label}</p>
                      <p className="text-right">{item.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-12">
        <button onClick={onCancel} type="button" className="btn-secondary">
          <span className="px-6">Previous</span>
        </button>
        <button type="button" onClick={onSubmit} className="btn">
          <span className="px-6">Confirm</span>
        </button>
      </div>
    </div>
  );
};

const PaymentIframe = ({ sessionId, onPay, onCancel }) => {
  useEffect(() => {
    // Add a listener for messages from the iframe
    window.addEventListener("message", handleIframeMessage);

    return () => {
      // Clean up the listener
      window.removeEventListener("message", handleIframeMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIframeMessage = (event) => {
    if (event.data === "onPay") {
      console.log("Calling onPay in React JS");
      onPay();
    } else if (event.data === "onCancel") {
      console.log("Calling onCancel in React JS");
      onCancel();
    }
  };

  // Construct the URL with query parameters
  const paymentPageUrl = `/html_files/payment-form.html?sessionId=${encodeURIComponent(
    sessionId
  )}`;

  return (
    <iframe
      src={paymentPageUrl}
      title="Payment Page"
      width="500"
      height="550"
      style={{ border: "none" }}
    ></iframe>
  );
};

const ThreedsChallenge = ({ apiResponse }) => {
  useEffect(() => {
    // Dynamically execute the script
    const script = document.createElement("script");
    script.id = "authenticate-payer-script";
    script.text = `
      var e = document.getElementById("threedsChallengeRedirectForm");
      if (e) {
        e.submit();
        if (e.parentNode !== null) {
          e.parentNode.removeChild(e);
        }
      }
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    // <div className="-w-[650px] max-h-[450px]- overflow-hidden-">
    <div dangerouslySetInnerHTML={{ __html: apiResponse }} />
    // </div>
  );
};
