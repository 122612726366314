import React from "react";
import useFirebase from "../hooks/useFirebase";
import useApp from "../context/AppState";
import { useAuth } from "../features/AuthSlice";
import { ReactComponent as LogoutPopupICON } from "../assets/svgs/Logout-Popup.svg";

const LogoutPopUp = () => {
  const { logOut } = useFirebase();
  const { logoutUser } = useAuth();
  const { closePopup } = useApp();
  const onLogout = () => {
    logOut();
    logoutUser();
    closePopup();
  };
  return (
    <div className="bg-sbg rounded-md px-28 py-12 flex flex-col items-center gap-14">
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-4">
          <div>
            <LogoutPopupICON className="w-11" />
          </div>
          <div className="text-2xl font-[500]">Logout</div>
        </div>
        <div className="text-lg text-stxt">
          Do you want to logout your account?
        </div>
      </div>
      <div className="flex gap-12">
        <button className="logout-popup-btn" onClick={closePopup}>
          No
        </button>
        <button className="logout-popup-btn bg-btnPrimary" onClick={onLogout}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default LogoutPopUp;
