const TRANSACTION_STATUSES = Object.freeze({
  PENDING: {
    value: 0,
    text: "PRocessing",
    description: "The top-up request is pending.",
  },
  SUCCESS: {
    value: 1,
    text: "Success",
    description: "The top-up request was successful.",
    className: "!text-cgreen",
  },
  FAILED: {
    value: 2,
    text: "Failed",
    description: "The top-up request failed.",
    className: "!text-cpink",
  },
});

export default TRANSACTION_STATUSES;

const ALL_TRANSACTIONS_STATUS = Object.values(TRANSACTION_STATUSES);

export function findTransactionStatus(value) {
  return (
    ALL_TRANSACTIONS_STATUS.find(
      (transcation) => transcation.value === Number(value)
    ) || {
      value: value,
      text: "Unknown",
      className: "",
    }
  );
}
