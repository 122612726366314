export const TRANSACTION_STATUS_SUCCESS = Object.freeze({
  value: 2,
  text: "Successful",
  className: "!text-cgreen",
});
export const TRANSACTION_STATUS_FAILED = Object.freeze({
  value: 3,
  text: "Failed",
  className: "!text-cyellow",
});

const TRANSACTION_STATUSES = Object.freeze({
  PENDING: {
    value: 1,
    text: "Pending",
    description: "The top-up request is pending.",
  },
  SUCCESS: {
    value: 2,
    text: "Success",
    description: "The top-up request was successful.",
    className: "!text-cgreen",
  },
  FAILED: {
    value: 3,
    text: "Failed",
    description: "The top-up request failed.",
    className: "!text-cpink",
  },
});

export default TRANSACTION_STATUSES;

const ALL_TRANSACTIONS_STATUS = Object.values(TRANSACTION_STATUSES);

export function findTransactionStatus(value) {
  return (
    ALL_TRANSACTIONS_STATUS.find(
      (transcation) => transcation.value === Number(value)
    ) || {
      value: value,
      text: "Unknown",
      className: "",
    }
  );
}
