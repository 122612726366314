import React, { useEffect, useState } from "react";
import IconTitleComponent from "./IconTitleComponent";
import balanceIcon from "../../assets/svgs/balance-icon.svg";
import mobileTopupIcon from "../../assets/svgs/mobile-topup-icon.svg";
import totalSalesIcon from "../../assets/svgs/total-sales-icon.svg";
import averageTransactionIcon from "../../assets/svgs/average-transaction-icon.svg";
import transactionCountIcon from "../../assets/svgs/transaction-count-icon.svg";
import supportIcon from "../../assets/svgs/support-icon.svg";
import Switch from "../../reusable/Switch";
import PATHS from "../../constants/RoutePaths";
import { Link } from "react-router-dom";
import EnvConstants from "../../constants/EnvConstants";
import formatAmount from "../../functions/formatAmount";
import { useUser } from "../../features/UserSlice";
import useGetNotificationSettings from "../../hooks/notifcationEmail/useGetNotificationSettings";
import useUpdateNotificationSettings from "../../hooks/notifcationEmail/useUpdateNotificationSettings";
import useGetAccountDetails from "../../hooks/useGetAccountDetails";
import Dropdown2 from "../../reusable/Dropdown2";

const Dashboard = () => {
  const { userBalance, notifyUserByEmail, notifyUserByEmailBalanceThreshold } =
    useUser();
  const { getNotificationSettings, loading: fetchingNotificationSetting } =
    useGetNotificationSettings();
  const { updateNotificationSettings, loading: updatingNotificationSetting } =
    useUpdateNotificationSettings();
  const { todaySalesStats, loading: fetchingTodaySalesStats } =
    useGetAccountDetails();
  const handleNotifyByEmailWhenBalanceRunsLowClick = () => {
    updateNotificationSettings(!notifyUserByEmail);
  };

  useEffect(() => {
    getNotificationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="space-y-1 mb-6">
        <p className="font-medium text-[1.75em] leading-5">Dashboard</p>
        <p className="font-light text-lg text-stxt">
          Welcome to Topup Dashboard
        </p>
      </div>
      <div className="grid tablet:grid-cols-2 gap-12">
        <div className="rounded-md bg-sbg p-4 laptop:p-[1.875em] flex flex-col items-center gap-14 w-full">
          <div className="w-full">
            <IconTitleComponent iconSrc={balanceIcon} title={"My Balance"} />
          </div>
          <p className="font-bold text-[2.5em] leading-5">
            {formatAmount(userBalance)}
          </p>
          <div className="flex gap-4">
            <Switch
              checked={notifyUserByEmail}
              onChange={handleNotifyByEmailWhenBalanceRunsLowClick}
              loading={
                fetchingNotificationSetting || updatingNotificationSetting
              }
            />
            <p className="text-lg">
              Notify me via email when my balance runs lower than{" "}
              {formatAmount(notifyUserByEmailBalanceThreshold)}
            </p>
          </div>
        </div>
        <div className="rounded-md bg-sbg p-4 laptop:p-[1.875em] flex flex-col items-center gap-16 w-full">
          <div className="space-y-6 w-full">
            <div>
              <IconTitleComponent
                iconSrc={mobileTopupIcon}
                title={"Mobile Top-up"}
              />
            </div>
            <p className="font-light text-lg text-stxt">
              Lorem ipsum dolor sit amet consectetur. Donec laoreet iaculis ac
              diam eleifend. Rhoncus sed pulvinar molestie feugiat laoreet
              iaculis ac diam.
            </p>
          </div>
          <Link to={PATHS.TOPUP} className="btn">
            <span className="text-[1.375em] leading-5 px-3 py-2 select-none font-medium">
              Mobile Topup
            </span>
          </Link>
        </div>
      </div>
      <div className="my-10 flex items-center gap-7 ">
        <p className="font-medium text-[1.375em] leading-5">
          {/* Today  */}
          Sales Overview
        </p>
        <div className="h-[1px] bg-stxt flex-grow"></div>
      </div>
      {fetchingTodaySalesStats ? (
        <div className="p-10 text-3xl bg-sbg rounded-md flex justify-center items-center">
          Loading...
        </div>
      ) : (
        <div className="flex justify-between items-center flex-wrap gap-8">
          {[
            {
              icon: totalSalesIcon,
              title: "Total Sales",
              value: formatAmount(todaySalesStats?.totalSales),
            },
            {
              icon: averageTransactionIcon,
              title: "Average Transaction",
              value: formatAmount(todaySalesStats?.averageTransaction || 0),
              // value: formatAmount(todaySalesStats?.averageTransaction),
            },
            {
              icon: transactionCountIcon,
              title: "Successful Transactions",
              value: todaySalesStats?.successfulTransactions,
            },
          ].map((item, index) => (
            <div
              key={index}
              className="p-10 bg-sbg rounded-md flex gap-10 items-center mb-5 flex-nowrap text-nowrap min-w-[24em] flex-grow"
            >
              <div>
                <img
                  src={item.icon}
                  className="h-[3.375em] w-[3.375em] min-h-[3.375em] min-w-[3.375em] max-h-[3.375em] max-w-[3.375em]"
                  alt=""
                />
              </div>
              <div className="space-y-2.5">
                <p className="font-light text-xl">{item.title}</p>
                <p className="font-bold text-2xl">{item.value}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <StatsSection />
      <div className="mt-7 text-center px-11 py-9 rounded-md bg-sbg space-y-6">
        <div className="flex justify-center">
          <IconTitleComponent iconSrc={supportIcon} title={"Support"} />
        </div>
        <div className="space-y-5">
          <p className="font-light text-xl text-stxt">
            Lorem ipsum dolor sit amet consectetur. At montes facilisi non dui
            ultricies viverra morbi proin. Vitae morbi tincidunt volutpat arcu
            habitant. Mauris mi orci blandit risus ultrices ac. Velit orci
            mattis faucibus vulputate imperdiet sollicitudin gravida. Sit amet
            id urna risus elit massa. Enim pretium nullam.
          </p>
          <div>
            <a
              href={`mailto:${EnvConstants.SUPPORT_EMAIL}`}
              className="text-xl text-ttxt hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {EnvConstants.SUPPORT_EMAIL}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

const StatsSection = () => {
  const graphFilters = [{ label: "View by Week", value: 0 }];
  const [graphFilter, setGraphFilter] = useState(0);
  const handleChangeGraphFilter = (e) => {
    setGraphFilter(e.target.value);
  };
  return (
    <>
      <div className="my-10 flex items-center gap-7 ">
        <p className="font-medium text-[1.375em] leading-5">Stats</p>
        <div className="h-[1px] bg-stxt flex-grow"></div>
        <Dropdown2
          value={graphFilter}
          onChange={handleChangeGraphFilter}
          options={graphFilters}
          disabled
        />
      </div>
      <div className="bg-sbg rounded-md p-7 grid place-items-center h-[40vh]">
        <p className="heading-text-2">Coming Soon...</p>
      </div>
    </>
  );
};
