import React, { useId, useState } from "react";

const ProfileLabelInputComponent = ({
  label,
  isEditable = true,
  isEditMode,
  type = "text",
  name,
  value,
  onChange,
  required = false,
  disabled = false,
  iconFocused,
  errorMessage = "Error Occured",
  pattern,
  ...props
}) => {
  const id = useId();
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  const error =
    pattern && errorMessage && value && !new RegExp(pattern).test(value);
  return (
    <div className="space-y-2.5">
      <label className="block cursor-pointer text-stxt" htmlFor={id}>
        {label}
      </label>
      {isEditMode ? (
        <div className="space-y-1">
          <label
            htmlFor={id}
            className={`block border-[1px] rounded-md px-2.5 py-2 cursor-text min-w-64 w-72 max-w-72 ${
              disabled || !isEditable
                ? "bg-[#ffffff33] border-transparent text-stxt"
                : `${isFocused ? "border-ptxt" : "border-stxt"}`
            }`}
          >
            <input
              {...props}
              id={id}
              type={type}
              name={name}
              value={value}
              disabled={disabled || !isEditable}
              required={required}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={error ? "true" : "false"}
              className="focus:outline-none bg-transparent cursor-text"
            />
          </label>
          {error && (
            <p className="text-cpink text-sm ">{errorMessage}</p>
          )}
        </div>
      ) : (
        <p className="text-lg font-medium py-2">{value}</p>
      )}
    </div>
  );
};

export default ProfileLabelInputComponent;
