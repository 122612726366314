// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/svgs/scroll-bar-btn-up.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/svgs/scroll-bar-btn-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 1.25em;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: var(--sbg);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--ttxt); 
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--ttxt); 
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-button:single-button {
  background-color: var(--sbg); 
}

.custom-scrollbar::-webkit-scrollbar-button:single-button:hover {
  background-color: var(--sbg); 
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-button:single-button:vertical {
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  height: 2.6875em;
}

.custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/styles/customScrollBar.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,aAAa;AACf;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,oBAAoB;EACpB,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,yDAA6D;AAC/D;;AAEA;EACE,yDAA+D;AACjE","sourcesContent":["/* Custom Scrollbar */\n.custom-scrollbar::-webkit-scrollbar {\n  width: 1.25em;\n}\n\n.custom-scrollbar::-webkit-scrollbar-track {\n  background-color: var(--sbg);\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb {\n  background-color: var(--ttxt); \n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb:hover {\n  background-color: var(--ttxt); \n  cursor: pointer;\n}\n\n.custom-scrollbar::-webkit-scrollbar-button:single-button {\n  background-color: var(--sbg); \n}\n\n.custom-scrollbar::-webkit-scrollbar-button:single-button:hover {\n  background-color: var(--sbg); \n  cursor: pointer;\n}\n\n.custom-scrollbar::-webkit-scrollbar-button:single-button:vertical {\n  background-position: center;\n  background-size: 50%;\n  background-repeat: no-repeat;\n  height: 2.6875em;\n}\n\n.custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement {\n  background-image: url(\"../assets/svgs/scroll-bar-btn-up.svg\");\n}\n\n.custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {\n  background-image: url(\"../assets/svgs/scroll-bar-btn-down.svg\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
