import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth"; // Only import the method
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firebase"; // Import the configured Firebase auth instance
import useFirebase from "../hooks/useFirebase"; // Import custom hook
import EnvConstants from "../constants/EnvConstants";
import CustomLink from "../reusable/CustomLink"; // Import CustomLink
import PATHS from "../constants/RoutePaths";
import Constants from "../constants/Constants";
import AuthPageComponent from "../reusable/AuthPageComponent";
import signupPasswordPageImage from "../assets/images/signup-page-image.png";
import { useAuth } from "../features/AuthSlice";
import useGetToken from "../hooks/auth/useGetToken";
import AuthenticationProvider from "../constants/AuthenticationProviders";
import googleIcon from "../assets/svgs/google-icon.svg";
import InputField from "../reusable/InputField";
import personIcon from "../assets/svgs/person-icon.svg";
import personIconFocused from "../assets/svgs/person-icon-focused.svg";
import emailIcon from "../assets/svgs/email-icon.svg";
import emailIconFocused from "../assets/svgs/email-icon-focused.svg";
import phoneIcon from "../assets/svgs/phone-icon.svg";
import phoneIconFocused from "../assets/svgs/phone-icon-focused.svg";
import passwordIcon from "../assets/svgs/password-icon.svg";
import passwordIconFocused from "../assets/svgs/password-icon-focused.svg";
import CheckBox from "../reusable/CheckBox";

const Signup = () => {
  const { isAuthenticated, setFirebaseUser } = useAuth();
  const getToken = useGetToken();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getFirebaseErrorMessage, sendVerificationEmailToUser } =
    useFirebase(); // Firebase error handler

  const location = useLocation();
  const navigate = useNavigate();

  const messageQueryParamKey = "msg";
  const errorQueryParamKey = "error";
  const msgNotErrorErrorValue = 0;
  const msgIsErrorErrorValue = 1;
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const msg = searchParams.get(messageQueryParamKey);
    if (msg) {
      setMessage(decodeURIComponent(msg));
    }
    const isErrorParam = searchParams.get(errorQueryParamKey);
    if (isErrorParam) {
      setIsError(+isErrorParam === msgIsErrorErrorValue);
    }
  }, [location.search]);

  const updateURLParams = (msg, isError) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(messageQueryParamKey, encodeURIComponent(msg));
    searchParams.set(
      errorQueryParamKey,
      isError ? msgIsErrorErrorValue : msgNotErrorErrorValue
    );
    navigate({ search: searchParams.toString() });
  };

  // Handle Firebase signup logic
  const handleSignup = async (e) => {
    e.preventDefault();
    setIsError(false);
    setMessage("");

    const inputs = Array.from(e.target.querySelectorAll("input"));
    const hasError = inputs.some(
      (input) => input.getAttribute("error") === "true"
    );

    if (hasError) {
      updateURLParams(
        "Please fix the errors in the form before submitting.",
        true
      );
      return;
    }

    if (
      EnvConstants.COMPLEX_PASSWORD_FEATURE_ENABLED &&
      !Constants.COMPLEX_PASSWORD_REGEX.test(password)
    ) {
      updateURLParams(
        "Password must be at least 8 characters long, include an uppercase letter, a number, and a special character.",
        true
      );
      return;
    }
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      sendVerificationEmailToUser(user, updateURLParams);
      // Handle successful signup
      updateURLParams(
        "Sign-up successful! A verification email has been sent to your email address. Please verify your email to complete the registration.",
        false
      );
      navigate(PATHS.SIGN_IN);
      /*
      getToken(
        JSON.parse(JSON.stringify(user)),
        AuthenticationProvider.EMAIL_AND_PASSWORD,
        onError,
        name,
        phoneNo
      );*/
    } catch (error) {
      console.log(error);
      const errorMessage = getFirebaseErrorMessage(error.code);
      updateURLParams(errorMessage, true);
    } finally {
      setLoading(false);
    }
  };

  if (isAuthenticated) {
    return <Navigate to={PATHS.DASHBOARD} replace />;
  }

  const handleGeneralErrors = (error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(`Error: \n\tCode: ${errorCode}\n\tMessage: ${errorMessage}`);
    updateURLParams(getFirebaseErrorMessage(errorCode), true);
    console.log(getFirebaseErrorMessage(errorCode));
    console.log(error);
    setLoading(false);
  };

  const onError = () => {
    handleGeneralErrors({
      code: 400,
      message: "An unexpected error has occurred. Please try again later.",
    });
  };

  const handleGoogleLogin = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // User signed in successfully
        const user = result.user;
        if (!user.emailVerified) {
          sendVerificationEmailToUser(user, updateURLParams);
          return;
        } else {
          // console.log("User signed in:", user);
          setFirebaseUser(user);
          getToken(
            JSON.parse(JSON.stringify(user)),
            AuthenticationProvider.GOOGLE,
            onError,
            null,
            null
          );
        }
      })
      .catch((error) => {
        if (error.code === "auth/popup-closed-by-user") {
          // Handle the case where user closed the popup
          console.log("Popup closed by user");
          setLoading(false);
        } else {
          handleGeneralErrors(error);
        }
      });
  };

  return (
    <>
      <AuthPageComponent
        imgSrcLeft={signupPasswordPageImage}
        titleLeft={"Integrate Technology"}
        descriptionLeft={
          "Lorem ipsum dolor sit amet consectetur. Sit luctus diam ipsum sed velit est lacus. Blandit et urna eget faucibus sodales auctor quam. Interdum quisque platea dictumst urna volutpat massa gravida."
        }
        titleRight={"Create your Account"}
        descriptionRight={"Join now for a seamless experience"}
      >
        {/* Google Button <-- Start -->  */}
        <div
          onClick={handleGoogleLogin}
          className="bg-sbg w-full flex justify-between items-center cursor-pointer gap-1 py-3 px-6 rounded-md"
        >
          <div className="select-none">
            <img className="size-6" src={googleIcon} alt="" />
          </div>
          <div className="select-none text-stxt text-lg">
            Continue as Google
          </div>
          <div className="select-none"></div>
        </div>
        {/* Google Button <-- END -->  */}
        <div className="flex w-full justify-between items-center gap-4">
          <div className="flex-grow h-[1px] bg-stxt rounded-sm"></div>
          <div className="text-stxt">Or continue with email</div>
          <div className="flex-grow h-[1px] bg-stxt rounded-sm"></div>
        </div>
        <form onSubmit={handleSignup} className="space-y-8">
          <div className="space-y-2.5">
            {/* If there's a message from the URL, show it here */}
            {message && (
              <div
                className={`mb-4 text-center ${
                  isError ? "text-cpink" : "text-cgreen"
                } bg-gray-700 p-2 rounded-md`}
              >
                {message}
              </div>
            )}
            <InputField
              icon={personIcon}
              iconFocused={personIconFocused}
              placeholder={"Name"}
              name={"fullName"}
              type={"text"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={loading}
              pattern={Constants.ALPHABETS_AND_SPACES_REGEX}
              errorMessage={"Name can only contain alphabet characters"}
            />
            <InputField
              icon={emailIcon}
              iconFocused={emailIconFocused}
              placeholder={"Email"}
              name={"email"}
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading}
              errorMessage={"Invalid email"}
              pattern={Constants.EMAIL_REGEX}
            />
            <InputField
              icon={phoneIcon}
              iconFocused={phoneIconFocused}
              placeholder={"Phone no."}
              name={"pnoneNo"}
              type={"tel"}
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              required={true}
              disabled={loading}
              pattern={Constants.PHONE_NO_REGEX}
              errorMessage="Invalid phone number"
              minLength={11}
              maxLength={11}
            />
            <InputField
              icon={passwordIcon}
              iconFocused={passwordIconFocused}
              placeholder={"Password"}
              name={"password"}
              type={"password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              required={true}
              maxLength={16}
            />
            <div className="flex gap-4 items-center">
              <CheckBox
                checked={agreeTerms}
                onChange={() => setAgreeTerms((val) => !val)}
                required
              />
              <div className="text-lg">
                I accepted the Reloadly&nbsp;
                <Link className="text-ttxt underline" to={PATHS.USER_TERMS_PAGE}>
                  Terms of Use
                </Link>
                .
              </div>
            </div>
          </div>
          <div>
            <button
              disabled={loading || !agreeTerms}
              type="submit"
              className={`btn w-full rounded-md ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? "Creating..." : "Create Account"}
            </button>
          </div>
          <div className="grid place-items-center text-center ">
            <div className="flex flex-wrap items-center text-lg">
              Already have an account? &nbsp;
              <CustomLink
                disabled={loading}
                to={PATHS.SIGN_IN}
                className="text-lg text-ttxt hover:underline"
              >
                Log in
              </CustomLink>
            </div>
          </div>
        </form>
      </AuthPageComponent>
    </>
  );
};

export default Signup;
