import React from "react";
import planetBeyondLogo from "../assets/images/planet-beyond-logo-light.png";

const AuthPageComponent = ({
  imgSrcLeft,
  titleLeft,
  descriptionLeft,
  titleRight,
  descriptionRight,
  children,
}) => {
  const appTitle = "reloadly";
  const appTitleInvertIndex = 2;
  return (
    <section className="h-svh w-full grid grid-cols-2 items-stretch">
      <section className="flex flex-col items-center w-full bg-sbg px-12 py-10">
        <div className="w-full">
          <img src={planetBeyondLogo} className="h-11" alt="" />
        </div>
        <div className="flex-grow grid place-items-center text-center">
          <div className="w-[30.78125vw]">
            <div className="w-full flex justify-center">
              <img src={imgSrcLeft} className="w-[82.631578947%]" alt="" />
            </div>
            <div className="space-y-6">
              <p className="font-semibold text-[1.625em] leading-5">
                {titleLeft}
              </p>
              <p className="font-light">{descriptionLeft}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="grid place-items-center">
        <div className="space-y-14 w-[25vw]">
          <div className="space-y-4">
            <div>
              {Array.from({ length: appTitle.length }, (_, index) => {
                const char = appTitle.charAt(index);
                const isInverted = index < appTitleInvertIndex;
                return (
                  <div key={index} className="relative inline-block text-ttxt text-[2.73125em] uppercase ">
                    <div className="bg-transparent font-extrabold">{char}</div>
                    <div
                      className={`bg-transparent absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
                        isInverted ? "text-pbg" : ""
                      } text-[0.95em]`}
                    >
                      {char}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="space-y-1">
              <p className="font-semibold text-4xl">{titleRight}</p>
              <p className="text-lg text-stxt">{descriptionRight}</p>
            </div>
          </div>
          {children}
        </div>
      </section>
    </section>
  );
};

export default AuthPageComponent;
