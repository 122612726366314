import React, { useState } from "react";
import editIcon from "../assets/svgs/pencil-938.svg";
import editIconFocused from "../assets/svgs/pencil-focused.svg";
import formatAmount from "../functions/formatAmount";
import { useUser } from "../features/UserSlice";
import useUpdateNotificationSettings from "../hooks/notifcationEmail/useUpdateNotificationSettings";
import { ReactComponent as TickIcon } from "../assets/svgs/check-icon-green.svg";
import { ReactComponent as CrossIcon } from "../assets/svgs/cross-icon-small-red.svg";

const ProfileNotificationSettings = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { notifyUserByEmailBalanceThreshold, notifyUserByEmail } = useUser();
  const { updateNotificationSettings, loading } =
    useUpdateNotificationSettings();
  const [threshold, setThreshold] = useState(notifyUserByEmailBalanceThreshold); // Set initial value from the user balance
  const [newThreshold, setNewThreshold] = useState(threshold);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (threshold !== newThreshold) {
      await updateNotificationSettings(notifyUserByEmail, newThreshold);
      setThreshold(newThreshold);
    }
    setIsEditing(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setNewThreshold(threshold);
    setIsEditing(false);
  };

  return (
    <div className="bg-sbg p-6 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <div className="text-stxt">
          <p>Balance Threshold: </p>
          {!isEditing ? (
            <div className="flex items-center gap-2">
              <p className="text-ptxt font-medium">{formatAmount(threshold)}</p>
              <EditIcon onClick={handleEdit} />
            </div>
          ) : (
            <form onSubmit={handleSave} className="flex items-center gap-1">
              <input
                type="number"
                value={newThreshold}
                onChange={(e) => setNewThreshold(e.target.value)}
                className="bg-tbg text-ptxt p-2 rounded-lg w-32"
                required
                min={1}
                maxLength={7}
              />
              <button type="submit" className="-" disabled={loading}>
                <TickIcon className="w-7 h-5 hover:scale-110 transition-all duration-200" />
              </button>
              <button onClick={handleCancel} disabled={loading}>
                <CrossIcon className="w-7 scale-125 hover:scale-[137.5%]  transition-all duration-200" />
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileNotificationSettings;

export const EditIcon = ({ onClick }) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  return (
    <span
      onClick={onClick}
      className="inline text-cblue hover:text-cgreen transition duration-200 cursor-pointer"
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <img className="h-4" src={focused ? editIconFocused : editIcon} alt="" />
    </span>
  );
};
