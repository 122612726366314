import { useAuth } from "../../features/AuthSlice";
import { useUser } from "../../features/UserSlice";
import useGetNetworkRules from "./useGetNetworkRules";
import axios from "axios";
import ApiConstants from "../../constants/ApiConstants";

const useGetToken = () => {
  const { saveToken } = useAuth();
  const { saveUser } = useUser();
  const getNetworkRules = useGetNetworkRules();
  const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_LOGIN;
  const getToken = async (
    user,
    authenticationProvider,
    onError,
    userName,
    phoneNo
  ) => {
    if (!user) {
      return;
    }
    try {
      const reqData = {
        fullName: userName || user?.providerData[0]?.displayName || null,
        email: user.email,
        token: user?.stsTokenManager?.accessToken,
        uid: user.uid,
        loginChannelId: authenticationProvider,
        phoneNumber: phoneNo || user?.providerData[0]?.phoneNumber,
      };
      const response = await axios.request({
        method: "POST",
        url: url,
        data: reqData,
      });
      const { data } = response?.data;
      const tokenObj = {
        token: data?.token,
        expiresAt: data?.tokenExpiry,
        refreshToken: data?.refreshToken,
        refreshTokenExpiry: data?.refreshTokenExpiry,
      };
      saveToken(tokenObj);
      getNetworkRules();
      saveUser(data?.webUser);
      console.log(data?.webUser);
    } catch (error) {
      console.error(error);
      onError();
    }
  };
  return getToken;
};

export default useGetToken;
