import React from "react";
import calenderIcon from "../assets/svgs/calender-icon.svg";

const DateInput = ({ placeholder = "", value, onChange, ...props }) => {
  return (
    <div className="relative">
      <div className="absolute pointer-events-none h-full w-full input-date">
        {value || placeholder}
      </div>
      <input
        value={value || ""}
        onChange={onChange}
        type="date"
        className="input-date w-full"
        {...props}
      />
      <img
        className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none"
        src={calenderIcon}
        alt=""
      />
    </div>
  );
};

export default DateInput;
