import React from "react";

const Dropdown2 = ({ name, value, onChange, options, disabled = false }) => {
  return (
    <div className="relative w-48">
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="appearance-none w-full px-4 py-2 text-ptxt bg-sbg cursor-pointer  border border-brdr  rounded-md  focus:outline-none  focus:ring-2  focus:ring-cblue  focus:border-cblue"
      >
        {Array.isArray(options) &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {/* Arrow Icon */}
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        {svgIconDropDown}
      </div>
    </div>
  );
};

export default Dropdown2;

const svgIconDropDown = (
  <svg
    className="w-4 h-4 text-gray-400"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9l-7 7-7-7"
    ></path>
  </svg>
);
