import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow} from "../../assets/svgs/back-arrow.svg";

const faqs = [
  {
    category: "General",
    items: [
      {
        question: "What is the Balance Top-Up Platform?",
        answer:
          "The Balance Top-Up Platform allows businesses, brands, and individuals to send mobile airtime (balance) to local and international numbers. It can be used for loyalty programs, employee incentives, research-based rewards, and more.",
      },
      {
        question: "Who can use this platform?",
        answer:
          "Both B2B (businesses and brands) and B2C (individuals) can use the platform. Businesses can integrate it into their systems, while individuals can send top-ups manually.",
      },
      {
        question: "What are the key use cases for this platform?",
        answer: (
          <ul className="list-disc list-inside">
            <li>
              <span className="font-semibold">Brands & Businesses: </span>
              Customer rewards, promotional incentives
            </li>
            <li>
              <span className="font-semibold">
                NGOs & Research Organizations:{" "}
              </span>
              Data collection incentives
            </li>
            <li>
              <span className="font-semibold">Retailers & E-commerce: </span>
              Loyalty rewards
            </li>
            <li>
              <span className="font-semibold">Individuals: </span>Personal
              mobile top-ups or sending balance to family/friends
            </li>
            <li>
              <span className="font-semibold">International Transfers: </span>
              Mobile balance transfers across different countries
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    category: "Platform Features",
    items: [
      {
        question: "What are the key features of the platform?",
        answer: (
          <ul className="list-disc list-inside">
            <li>
              <span className="font-semibold">Instant Top-Ups: </span>
              Quick balance transfers to any mobile number
            </li>
            <li>
              <span className="font-semibold">Bulk Top-Ups: </span>
              Send airtime to multiple numbers at once
            </li>
            <li>
              <span className="font-semibold">API Integration: </span>
              Businesses can integrate the platform with their apps and systems
            </li>
            <li>
              <span className="font-semibold">Multi-Operator Support: </span>
              Works with multiple telecom operators
            </li>
            <li>
              <span className="font-semibold">Real-Time Reports: </span>
              Track usage and transaction history
            </li>
          </ul>
        ),
      },
      {
        question: "Can the platform handle bulk transactions?",
        answer:
          "Yes, the platform supports bulk top-ups, allowing businesses to send mobile balance to multiple recipients in one go.",
      },
      {
        question: "Does the platform provide reports and analytics?",
        answer:
          "Yes, businesses can access real-time reports on transactions, usage trends, and spending patterns.",
      },
    ],
  },
  {
    category: "Payments & Pricing",
    items: [
      {
        question: "What payment methods are supported?",
        answer: (
          <>
            <p>The platform supports multiple payment methods, including:</p>
            <ul className="list-disc list-inside">
              <li>
                <span className="font-semibold">Bank transfers</span>
              </li>
              <li>
                <span className="font-semibold">Credit/debit cards</span>
              </li>
              <li>
                <span className="font-semibold">Digital wallets </span>
                (depending on integrations){" "}
              </li>
            </ul>
          </>
        ),
      },
      {
        question: "Is there a minimum or maximum transaction limit?",
        answer:
          "Yes, limits may apply depending on the telecom operator, country, and payment method. Businesses can request customized limits.",
      },
      {
        question: "Are there any service charges?",
        answer:
          "Charges depend on the volume of credit purchased. Further details can be inquired during onboarding and credit purchasing.",
      },
    ],
  },
  {
    category: "Support & Policies",
    items: [
      {
        question: "Is there customer support available?",
        answer:
          "Yes, 24/7 customer support is available for technical issues, transaction queries, and integration assistance.",
      },
      {
        question: "How do I sign up?",
        answer: "You can sign up directly through the platform.",
      },
      {
        question: "Is there any return policy?",
        answer:
          "There is no applicable return policy. The purchased credit is non-refundable.",
      },
    ],
  },
];

const FAQ = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <section className="space-y-5 flex flex-col h-full">
        <div className="flex gap-6 items-center">
          <button onClick={handleGoBack}>
            <BackArrow className="size-[1.625em] hover:scale-105" />
          </button>
          <p className="heading-text">{`FAQ`}</p>
        </div>
        <section className="bg-sbg flex-grow overflow-hidden">
          <section className="overflow-y-auto h-full px-12 pt-9 pb-24 rounded-md">
            <div className="">
              <ul className="text-ttxt text-xl space-y-10">
                {faqs.map((section, sectionIdx) => (
                  <div key={sectionIdx} className="mb-6">
                    <h2 className="text-xl font-semibold mb-3">
                      {section.category}
                    </h2>
                    {section.items.map((faq, index) => {
                      const isOpen = openIndex === `${sectionIdx}-${index}`;
                      return (
                        <div key={index} className="border-b border-brdr py-2">
                          <button
                            onClick={() => toggleFAQ(`${sectionIdx}-${index}`)}
                            className="w-full text-left flex justify-between items-center text-ptxt font-medium p-2 focus:outline-none"
                          >
                            {faq.question}
                            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                          </button>
                          {isOpen && (
                            <p className="text-stxt mt-2 p-2">{faq.answer}</p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </ul>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default FAQ;
