import React, { useState } from "react";
import formatAmount from "../../functions/formatAmount";
import { useUser } from "../../features/UserSlice";
import Switch from "../../reusable/Switch";
import useUpdateNotificationSettings from "../../hooks/notifcationEmail/useUpdateNotificationSettings";
import { ReactComponent as TickIcon } from "../../assets/svgs/check-icon-green.svg";
import { ReactComponent as CrossIcon } from "../../assets/svgs/cross-icon-small-red.svg";
import { EditIcon } from "../../reusable/ProfileNotificationSettings";
import { useRechargeWallet } from "./RechargeWallet";

const LowBalanceAlert = ({ atmCards, refreshTransactionsData }) => {
  const { userBalance, notifyUserByEmail, notifyUserByEmailBalanceThreshold } =
    useUser();
  const { updateNotificationSettings, loading: updatingNotificationSetting } =
    useUpdateNotificationSettings();
  const handleNotifyByEmailWhenBalanceRunsLowClick = () => {
    updateNotificationSettings(!notifyUserByEmail);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [threshold, setThreshold] = useState(notifyUserByEmailBalanceThreshold); // Set initial value from the user balance
  const [newThreshold, setNewThreshold] = useState(threshold);
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (threshold !== newThreshold) {
      await updateNotificationSettings(notifyUserByEmail, newThreshold);
      setThreshold(newThreshold);
    }
    setIsEditing(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setNewThreshold(threshold);
    setIsEditing(false);
  };

  const rechargeWallet = useRechargeWallet({
    atmCards,
    refreshTransactionsData,
  });

  return (
    <div className="p-7 bg-sbg rounded-md h-full">
      <div className="mb-11 flex gap-1 w-full justify-between items-center text-3xl">
        <p className="font-light">Low Balance Alert</p>
        <p className="font-medium">{formatAmount(userBalance)}</p>
      </div>
      <div className="mb-10 space-y-4">
        <div className="flex w-full gap-1 justify-between items-center">
          <p className="text-stxt text-lg">
            Notify me via email when my balance runs lower than{" "}
            {formatAmount(notifyUserByEmailBalanceThreshold)}
          </p>
          <div className="text-[13px]">
            <Switch
              checked={notifyUserByEmail}
              onChange={handleNotifyByEmailWhenBalanceRunsLowClick}
              loading={updatingNotificationSetting}
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <p className="text-stxt text-lg">Set Alert for Minimum Balance</p>
          <div>
            <form onSubmit={handleSave} className="flex items-center gap-2">
              <label
                htmlFor="threshold"
                className="flex items-center bg-tbg text-ptxt p-2 rounded-lg w-32"
              >
                <p className="text-stxt">Rs&nbsp;</p>
                <input
                  id="threshold"
                  type="number"
                  value={newThreshold}
                  onChange={(e) => setNewThreshold(e.target.value)}
                  className={`flex-grow w-full focus:outline-none bg-transparent disabled:text-stxt text-ptxt `}
                  required
                  min={1}
                  maxLength={7}
                  disabled={!isEditing}
                />
              </label>
              {isEditing ? (
                <div className="flex">
                  <button
                    type="submit"
                    className="-"
                    disabled={updatingNotificationSetting}
                  >
                    <TickIcon className="w-7 h-5 hover:scale-110 transition-all duration-200" />
                  </button>
                  <button
                    onClick={handleCancel}
                    disabled={updatingNotificationSetting}
                  >
                    <CrossIcon className="w-7 scale-125 hover:scale-[137.5%]  transition-all duration-200" />
                  </button>
                </div>
              ) : (
                <EditIcon onClick={handleEdit} />
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button onClick={rechargeWallet} className="btn">
          Recharge Wallet
        </button>
      </div>
    </div>
  );
};

export default LowBalanceAlert;
