import { useEffect, useMemo, useState } from "react";
import { findTransactionStatus } from "../../constants/Reports/transactions/TransactionStatus";
import useApi from "../useApi";
import ApiConstants from "../../constants/ApiConstants";
import { toTitleCase } from "../../functions/stringFunctions";

const useGetReport = () => {
  const [transactions, setTransactions] = useState([]);
  const [transactionsCount, setTransactionsCount] = useState([]);
  const [filter, setFilterState] = useState(null);
  const { makeApiCall, loading } = useApi();

  const setFilter = (transactionId, page, size) => {
    setFilterState({
      transactionId: transactionId,
      page: page,
      size: size,
    });
  };

  useEffect(() => {
    if (filter) {
      fetchReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const transactionsFormatted = useMemo(
    () =>
      transactions.map((transaction) => ({
        ...transaction,
        amount: `Rs ${transaction.amount.toLocaleString()}`,
        operator: toTitleCase(transaction?.operator),
        status: findTransactionStatus(transaction.statusCode),
      })),
    [transactions]
  );

  const fetchReports = async () => {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_GET_REPORT_DETAILS}`;
    const params = {
      page: filter?.page - 1,
      size: filter?.size,
      transaction_id: filter?.transactionId,
    };
    try {
      const response = await makeApiCall({
        url,
        params,
      });
      /*
      const response2 = {
        data: {
          status: true,
          message: "Transactions fetched successfully.",
          data: {
            totalPages: 1,
            totalElements: 3,
            size: 10,
            pageNumber: 0,
            content: [
              {
                msisdn: "923001234582",
                operator: "zong",
                amount: 1000,
                status: 2,
              },
              {
                msisdn: "923001234582",
                operator: "zong",
                amount: 1000,
                status: 2,
              },
              {
                msisdn: "923001234582",
                operator: "zong",
                amount: 1000,
                status: 2,
              },
            ],
          },
        },
      };
      console.log(response?.data);
       */
      const { data } = response?.data;
      setTransactions(data?.content || []);
      setTransactionsCount({
        count: data.totalElements,
        amount: null, // Not Fetched Yet
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    transactions,
    transactionsCount,
    transactionsFormatted,
    filter,
    setFilter,
    fetching: loading,
  };
};

export default useGetReport;
