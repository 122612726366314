import React, { useContext } from "react";
import { appContext as AppContext } from "./context";
import { useState } from "react";

export const AppContextProvider = ({ children }) => {
  
  // POPUP <<-- START -->>
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupChildren, setPopupChildren] = useState(<></>);
  const showPopup = (popupChildren) => {
    if (popupChildren) {
      setPopupChildren(popupChildren);
      setIsPopupOpen(true);
    } else {
      alert("No Pop Up Component Found");
    }
  };
  const closePopup = () => {
    setPopupChildren(<></>);
    setIsPopupOpen(false);
  };
  // POPUP <<-- END -->>

  return (
    <AppContext.Provider value={{ showPopup, closePopup }}>
      {isPopupOpen ? (
        <section className="fixed z-[999999] top-0 left-0 h-svh w-screen bg-[#141B2DCC] grid place-items-center">
          {popupChildren}
        </section>
      ) : (
        <></>
      )}
      {children}
    </AppContext.Provider>
  );
};

const useApp = () => useContext(AppContext);

export default useApp;
