import React from "react";
import LoaderSmall from "./LoaderSmall";

const TextWithLoader = ({ className = "", text, showLoader }) => {
  return showLoader ? (
    <div className={` ${className} flex items-center gap-1`}>
      <p>{text}</p>
      <LoaderSmall />
    </div>
  ) : (
    <div className={`${className}`}>{text}</div>
  );
};

export default TextWithLoader;
