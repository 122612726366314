import React, { useEffect, useMemo, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import NotFound from "../../pages/NotFound";
import backArrow from "../../assets/svgs/back-arrow.svg";
import Loader from "../../reusable/Loader";
// import backIcon from "../../assets/svgs/back-icon.svg";
// import backIconDisabled from "../../assets/svgs/back-icon-disabled.svg";
// import nextIcon from "../../assets/svgs/next-icon.svg";
// import nextIconDisabled from "../../assets/svgs/next-icon-disabled.svg";
import { toTitleCase } from "../../functions/stringFunctions";
import formatAmount from "../../functions/formatAmount";
import useGetFileContent from "../../hooks/topup/files/useGetFileContent";
import PATHS from "../../constants/RoutePaths";
import PaymentSentPopup from "../../reusable/PaymentSentPopup";
import PaymentSendingPopup from "../../reusable/PaymentSendingPopup";
import useApp from "../../context/AppState";
import All_Networks from "../../data/Networks";
import useMakeCampaign from "../../hooks/topup/files/useMakeCampaign";
import useGetAccountDetails from "../../hooks/useGetAccountDetails";
import { useUser } from "../../features/UserSlice";
import PaymentFailedPopUp from "../../reusable/PaymentFailedPopUp";

const ViewFile = () => {
  const navigate = useNavigate();
  const { showPopup } = useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { file } = location.state || {};
  const {
    fileContent: entries,
    fileInfo,
    setFilter,
    fetching: loading,
  } = useGetFileContent();
  // Retrieve the 'p' parameter
  const page = +searchParams.get("p") || 1;
  const setPage = (value) => setSearchParams({ p: value }, { state: { file } });
  const pageSize = 7;
  const maxPages = fileInfo?.totalPages;

  //   Set 'p' to 0 if it is null
  useEffect(() => {
    if (!page || page < 1) {
      setSearchParams({ p: "1" });
    }
  }, [page, setSearchParams]);

  useEffect(() => {
    if (page >= 1) {
      setFilter(file?.fileId, page, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!file) {
    return <NotFound />;
  }

  const handleGoBack = () => {
    navigate(-2);
  };

  const handleNext = () => {
    showPopup(<PopUp data={file} />);
  };

  return (
    <section className="space-y-5 flex flex-col h-full">
      <div className="flex gap-6 items-baseline">
        <button onClick={handleGoBack}>
          <img className="size-[1.625em]" src={backArrow} alt="<" />
        </button>
        <div className="space-y-2">
          <p className="heading-text">{`Uploaded File:`}</p>
          <p className="text-ttxt">{`File name: ${file?.filename}`}</p>
        </div>
      </div>
      <section className={`relative bg-sbg flex-grow overflow-hidden`}>
        {loading ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <section
          className={`${
            loading ? "opacity-0 pointer-events-none" : ""
          } flex flex-col h-full`}
        >
          <div className="p-6 flex-grow overflow-y-auto">
            <table className="min-w-full table-auto text-left">
              <thead>
                <tr className="bg-tbg">
                  <th className="report-table-header">MSISDN</th>
                  <th className="report-table-header">Operator Name</th>
                  <th className="report-table-header">Amount</th>
                  <th className="report-table-header"></th>
                  <th className="report-table-header !text-stxt !font-light text-[0.8125em]">
                    <div>
                      {maxPages > 0 && (
                        <div className="flex justify-end gap-6 flex-wrap">
                          <p>{`Showing ${(page - 1) * pageSize + 1}-${
                            (page - 1) * pageSize + entries?.length
                          }`}</p>
                          <div className="flex gap-6 items-center">
                            <button
                              disabled={page <= 1}
                              onClick={() => setPage(page - 1)}
                              className="text-stxt text-lg disabled:opacity-20"
                            >
                              &lt;
                              {/* <img
                              className="sixe-[0.875em]"
                              src={page <= 1 ? backIconDisabled : backIcon}
                              alt="<"
                            /> */}
                            </button>
                            <button
                              disabled={page >= maxPages}
                              onClick={() => setPage(page + 1)}
                              className="text-stxt text-lg disabled:opacity-20"
                            >
                              &gt;
                              {/* <img
                              className="sixe-[0.875em]"
                              src={
                                page >= maxPages ? nextIconDisabled : nextIcon
                              }
                              alt=">"
                            /> */}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => {
                  return (
                    <tr key={index} className="report-table-row">
                      <td className="report-table-cell">
                        {entry?.msisdn}
                      </td>
                      <td className="report-table-cell !text-ttxt">
                        {toTitleCase(entry?.operator || "Unknown")}
                      </td>
                      <td className={`report-table-cell`}>
                        {formatAmount(entry?.amount || "")}
                      </td>
                      <td
                        colSpan={2}
                        className={`report-table-cell !text-left !text-cyellow !text-xs text-wrap w-[15vw]`}
                      >
                        {entry?.remarks}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="bg-tbg py-3 px-7 flex justify-between items-center gap-4">
            <div className="flex items-center gap-12"></div>
            <div>
              <div className="flex justify-center w-full gap-5">
                <Link
                  className="btn-secondary rounded-sm"
                  to={PATHS.TOPUP_FILES}
                >
                  <span className="select-none px-8 ">Back</span>
                </Link>
                <button className="btn rounded-sm" onClick={handleNext}>
                  <span className="select-none px-8 ">Next</span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default ViewFile;

const PopUp = ({ data }) => {
  const { closePopup } = useApp();
  const navigate = useNavigate();
  const { makeCampaign } = useMakeCampaign();
  const steps = Object.freeze({
    CONFIRM_TRANSACTION: 0,
    SENDING_PAYMENT: 1,
    PAYMENT_SENT: 2,
    PAYMENT_FAILED: 3,
  });
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(steps.CONFIRM_TRANSACTION);
  const [errorMessage, setErrorMessage] = useState(null);

  const formattedData = useMemo(() => {
    const result = [];
    All_Networks.forEach((network) => {
      const value = data?.[network?.networkName.toLowerCase()];
      if (!isNaN(value) && +value > 0) {
        result.push({
          operator: network?.networkName,
          msisdnCount: value,
        });
      }
    });
    return result;
  }, [data]);

  const { getAccountDetails } = useGetAccountDetails();
  const { userBalance } = useUser();
  useEffect(() => {
    getAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return (
      <>
        <button className="btn" onClick={closePopup}>
          Close
        </button>
      </>
    );
  }

  const doNotHasEnoughBalance = userBalance < data?.totalAmount;

  const handleNext = async () => {
    setStep(steps.SENDING_PAYMENT);
    const result = await makeCampaign(data?.fileId);
    if (result.status) {
      setStep(steps.PAYMENT_SENT);
      getAccountDetails();
    } else {
      setStep(steps.PAYMENT_FAILED);
      setErrorMessage(result.message);
    }
  };
  const paymentSentOnCancel = () => {
    closePopup();
    navigate(PATHS.TOPUP);
  };
  const paymentSentOnViewReceipt = () => {
    closePopup();
    navigate(PATHS.REPORTS);
  };

  return step === steps.CONFIRM_TRANSACTION ? (
    <div className="py-9 px-16 bg-sbg flex flex-col items-center gap-14  rounded-md">
      <p className="font-light text-3xl">Please confirm your payment request</p>
      <div className="grid grid-cols-2">
        <div className="flex items-center h-full min-w-60">
          <div className="space-y-10 w-full border-r-[0.5px] border-stxt">
            <div className="space-y-2.5">
              <p className="font-light text-[1.375em] leading-5">
                Total MSISDNs
              </p>
              <p className="font-semibold text-[1.625em] leading-5 text-ttxt">
                {data?.totalMsisdn}
              </p>
            </div>
            <div className="space-y-2.5">
              <p className="font-light text-[1.375em] leading-5">
                Total Amount
              </p>
              <p className="font-semibold text-[1.625em] leading-5 text-ttxt">
                {data?.totalAmount}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center h-full pl-16">
          <div className="space-y-4">
            {formattedData.map((item, index) => (
              <div key={index}>
                <p className="text-lg">{toTitleCase(item.operator)}</p>
                <div className="flex justify-between gap-9 font-light text-stxt">
                  <div>Msisdns: {item.msisdnCount}</div>
                  {item.totalAmount && <div>T.Amount: {item.totalAmount}</div>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {doNotHasEnoughBalance && (
        <div className="flex justify-center text-center">
          <p className="text-cyellow underline">Not Enough Balance</p>
        </div>
      )}
      <div className="flex justify-center w-full gap-12">
        <button className="btn-secondary rounded-sm" onClick={closePopup}>
          <span className="select-none px-8 ">Cancel</span>
        </button>
        <button
          className="btn rounded-sm"
          disabled={doNotHasEnoughBalance}
          onClick={handleNext}
        >
          <span className="select-none px-8 ">Confirm</span>
        </button>
      </div>
    </div>
  ) : step === steps.SENDING_PAYMENT ? (
    <PaymentSendingPopup />
  ) : step === steps.PAYMENT_SENT ? (
    <PaymentSentPopup
      onCancel={paymentSentOnCancel}
      onViewReceipt={paymentSentOnViewReceipt}
    />
  ) : (
    <PaymentFailedPopUp
      onCancel={paymentSentOnCancel}
      errorMessage={errorMessage}
    />
  );
};
