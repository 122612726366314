import React, { useState } from "react";
import "../styles/side-bar.css";
import planetBeyondLogoLight from "../assets/images/planet-beyond-logo-light.png";
import personIcon from "../assets/images/person.png";
import Home_Icon from "../assets/svgs/Home-Icon.svg";
import Home_Icon_Active from "../assets/svgs/Home-Icon-active.svg";
import Topup_Icon from "../assets/svgs/Topup-Icon.svg";
import Topup_Icon_Active from "../assets/svgs/Topup-Icon-active.svg";
import Wallet_Icon from "../assets/svgs/wallet-icon.svg";
import Wallet_Icon_Active from "../assets/svgs/wallet-icon-active.svg";
import Reports_Icon from "../assets/svgs/Reports-icon.svg";
import Reports_Icon_Active from "../assets/svgs/Reports-icon-active.svg";
import Profile_Icon from "../assets/svgs/profile-icon.svg";
import Profile_Icon_Active from "../assets/svgs/profile-icon-active.svg";
import Logout_Icon from "../assets/svgs/Logout-icon.svg";
import Logout_Icon_Active from "../assets/svgs/Logout-icon-active.svg";
import { Link, useLocation } from "react-router-dom";
import PATHS from "../constants/RoutePaths";
import useApp from "../context/AppState";
import LogoutPopUp from "./LogoutPopUp";
import { useAuth } from "../features/AuthSlice";
import ImageFetcher from "../reusable/ImageFetcher";
import formatAmount from "../functions/formatAmount";
import { useUser } from "../features/UserSlice";

const SideBar = () => {
  return (
    <aside className="side-bar-container custom-scrollbar">
      <LogoSection />
      <ProfileSection />
      <SideBarMenu />
    </aside>
  );
};

const LogoSection = () => {
  return (
    <div className="side-bar-logo-section-container side-bar-bottom-border">
      <div>
        <img
          className="w-[12.8125em]"
          src={planetBeyondLogoLight}
          alt="Planet Beyond"
        />
      </div>
    </div>
  );
};

const ProfileSection = () => {
  const { firebaseUser } = useAuth();
  const { userBalance, user } = useUser();
  return (
    <div className="flex flex-col items-center gap-7 w-full py-6 side-bar-bottom-border">
      <div>
        {firebaseUser?.photoURL ? (
          <ImageFetcher
            className="rounded-full size-[5.625em]"
            src={firebaseUser?.photoURL}
          />
        ) : (
          <img
            className="rounded-full size-[5.625em]"
            src={personIcon}
            alt="User"
          />
        )}
      </div>
      <div className="text-center font-[500]">
        <p className="text-2xl">{user?.fullName}</p>
        <p className="text-lg text-ttxt">{formatAmount(userBalance)}</p>
      </div>
    </div>
  );
};

const SideBarMenu = () => {
  const { showPopup } = useApp();
  const location = useLocation();
  const pathName = location.pathname;
  const handleLogout = () => {
    showPopup(<LogoutPopUp />);
  };
  const items = [
    {
      name: "Dashboard",
      isActive: pathName.startsWith(PATHS.DASHBOARD) || pathName === PATHS.BASE,
      inactiveIcon: Home_Icon,
      activeIcon: Home_Icon_Active,
      isLink: true,
      to: PATHS.DASHBOARD,
    },
    {
      name: "Topup",
      isActive: pathName.startsWith(PATHS.TOPUP),
      inactiveIcon: Topup_Icon,
      activeIcon: Topup_Icon_Active,
      isLink: true,
      to: PATHS.TOPUP,
    },
    {
      name: "My Wallet",
      isActive: pathName.startsWith(PATHS.WALLET),
      inactiveIcon: Wallet_Icon,
      activeIcon: Wallet_Icon_Active,
      isLink: true,
      to: PATHS.WALLET,
    },
    {
      name: "Reports",
      isActive: pathName.startsWith(PATHS.REPORTS),
      activeIcon: Reports_Icon_Active,
      inactiveIcon: Reports_Icon,
      isLink: true,
      to: PATHS.REPORTS,
    },
    {
      name: "Profile",
      isActive: pathName.startsWith(PATHS.PROFILE),
      activeIcon: Profile_Icon_Active,
      inactiveIcon: Profile_Icon,
      isLink: true,
      to: PATHS.PROFILE,
    },
    {
      name: "Logout",
      isActive: false,
      inactiveIcon: Logout_Icon,
      activeIcon: Logout_Icon_Active,
      isLink: false,
      onClick: handleLogout,
    },
  ];
  return (
    <div>
      <div className="px-10 py-7 text-stxt text-[0.875em]">Menu</div>
      {items.map((item, index) => (
        <SideBarItem key={index} item={item} />
      ))}
    </div>
  );
};

const SideBarItem = ({ item }) => {
  const [isFocused, setFocused] = useState(item.isActive);

  const content = (
    <div className={`bg-ttxt`}>
      <div
        onMouseEnter={() => setFocused(true)} // Change to hover image on mouse enter
        onMouseLeave={() => setFocused(false)} // Revert to default image on mouse leave
        className={`${
          item.isActive || isFocused
            ? "bg-[#2a3755] text-ttxt font-[500]"
            : "bg-sbg"
        } ${
          item.isActive
            ? "ml-2 pl-4 tablet:pl-6 laptop:pl-8"
            : "pl-6 tablet:pl-8 laptop:px-10"
        } py-4 transition-all duration-300 flex items-center gap-5`}
      >
        <img
          className="size-6"
          src={item.isActive || isFocused ? item.activeIcon : item.inactiveIcon}
          alt=""
        />
        <p>{item.name}</p>
      </div>
    </div>
  );
  return item.isLink ? (
    <Link to={item.to}>{content}</Link>
  ) : (
    <button className="w-full" onClick={item.onClick}>
      {content}
    </button>
  );
};

export default SideBar;
