// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-bar-container {

  overflow-y: auto;

  background-color: var(--sbg);

  padding-bottom: 2.5rem
}

.side-bar-logo-section-container {

  padding-top: 2.5rem;

  padding-bottom: 1.5rem
}

.side-bar-bottom-border {

  border-bottom-width: 0.3px;

  border-bottom-color: var(--brdr)
}

/* DEFAULT STYLES */
.side-bar-container {

  min-width: 20rem
}

.side-bar-logo-section-container {

  padding-left: 3rem;

  padding-right: 3rem
}

/* VIEW PORT LESS THAN 1700 PX */
@media screen and (max-width: 1700px) {
  .side-bar-container {

    min-width: 18rem
  }

  .side-bar-logo-section-container {

    padding-left: 2.25rem;

    padding-right: 2.25rem
  }
}

/* VIEW PORT LESS THAN 1440 PX */
@media screen and (max-width: 1440px) {
  .side-bar-container {

    min-width: 15rem
  }

  .side-bar-logo-section-container {

    padding-left: 2.25rem;

    padding-right: 2.25rem
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/side-bar.css"],"names":[],"mappings":"AACE;;EAAA,gBAAmC;;EAAnC,4BAAmC;;EAAnC;AAAmC;;AAInC;;EAAA,mBAAiB;;EAAjB;AAAiB;;AAIjB;;EAAA,0BAAqC;;EAArC;AAAqC;;AAGvC,mBAAmB;AAEjB;;EAAA;AAAe;;AAIf;;EAAA,kBAAY;;EAAZ;AAAY;;AAGd,gCAAgC;AAChC;EAEI;;IAAA;EAAe;;EAIf;;IAAA,qBAAW;;IAAX;EAAW;AAEf;;AAEA,gCAAgC;AAChC;EAEI;;IAAA;EAAe;;EAIf;;IAAA,qBAAW;;IAAX;EAAW;AAEf","sourcesContent":[".side-bar-container {\n  @apply bg-sbg pb-10 overflow-y-auto;\n}\n\n.side-bar-logo-section-container {\n  @apply pt-10 pb-6;\n}\n\n.side-bar-bottom-border {\n  @apply border-b-[0.3px] border-b-brdr;\n}\n\n/* DEFAULT STYLES */\n.side-bar-container {\n  @apply min-w-80;\n}\n\n.side-bar-logo-section-container {\n  @apply px-12;\n}\n\n/* VIEW PORT LESS THAN 1700 PX */\n@media screen and (max-width: 1700px) {\n  .side-bar-container {\n    @apply min-w-72;\n  }\n\n  .side-bar-logo-section-container {\n    @apply px-9;\n  }\n}\n\n/* VIEW PORT LESS THAN 1440 PX */\n@media screen and (max-width: 1440px) {\n  .side-bar-container {\n    @apply min-w-60;\n  }\n\n  .side-bar-logo-section-container {\n    @apply px-9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
