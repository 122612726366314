import React from "react";
import crossIcon from "../assets/svgs/cross-icon-small.svg";
import verifyIcon from "../assets/svgs/verify.svg";

const SuccessPopUp = ({
  title = "Success",
  description,
  onCancel,
  mainBtnText = "Close",
  onMainBtnClick,
}) => {
  return (
    <div className="bg-sbg rounded-md p-5">
      <div className="flex justify-end w-full">
        <button onClick={onCancel}>
          <img src={crossIcon} className="size-4" alt="X" />
        </button>
      </div>
      <div className="px-20 pb-5 flex flex-col w-full items-center">
        <div className="flex flex-col w-full items-center gap-10">
          <img src={verifyIcon} className="size-[4.375em]" alt="" />
          <div className="text-center space-y-3">
            <p className="font-medium text-2xl">{title}</p>
            <p className="font-light text-lg text-stxt">{description}</p>
          </div>
          <button onClick={onMainBtnClick} className="btn">
            <span className="px-3 select-none">{mainBtnText}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopUp;
