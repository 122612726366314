/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import validateMsisdn from "../../functions/validateMsisdn";
import { getNetwork } from "../../data/Networks";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import infoIcon from "../../assets/svgs/info-icon.svg";
import infoIconRed from "../../assets/svgs/info-icon-red.svg";
import { useNavigate } from "react-router-dom";
import PATHS from "../../constants/RoutePaths";
import hasDuplicates from "../../functions/hasDuplicates";
import EnvConstants from "../../constants/EnvConstants";

const MultipleMsisdnTopupEnterNumber = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const [errors, setErrors] = useState([]);

  function isValidInput(text) {
    const regex = /^[0-9, ]*$/; // Regular expression to match only numbers, commas, and spaces
    return regex.test(text);
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (isValidInput(value)) {
      setInput(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const msisdns = input.split(",").map((msisdn) => msisdn.trim());
    const validateInput = () => {
      const newErrors = [];

      msisdns.forEach((msisdn, index) => {
        if (!validateMsisdn(msisdn)) {
          let message = "";

          if (msisdn.length !== 12) {
            message = `Mobile Number "${msisdn}" must be exactly 12 digits long.`;
          } else if (!msisdn.startsWith("923")) {
            message = `Mobile Number "${msisdn}" must start with "923".`;
          } else if (isNaN(msisdn)) {
            message = `Mobile Number "${msisdn}" must only contain numeric characters.`;
          } else {
            message = `Mobile Number "${msisdn}" is invalid.`;
          }

          newErrors.push({
            index,
            msisdn,
            message,
          });
        } else if (!getNetwork(msisdn)) {
          const message = `Mobile Number "${msisdn}" does not belong to a valid network.`;
          newErrors.push({
            index,
            msisdn,
            message,
          });
        }
      });
      setErrors(newErrors);
      /** Prevent Duplicate Msisdns */
      if (!EnvConstants.MULTIPLE_MSISDN_TOP_UP_ALLOW_DUPLICATES && newErrors.length === 0 && hasDuplicates(msisdns)) {
        newErrors.push({
          index: 0,
          msisdn: "",
          message: "List Contains Duplicates",
        });
      }
      return newErrors.length === 0;
    };

    if (validateInput()) {
      navigate(PATHS.TOPUP_MULTIPLE_MSISDN_ENTER_AMOUNT, {
        state: { msisdns },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} id="multiple-msisdn-enter-number-section">
      <div className="w-full heading-text-2">Multiple Mobile Numbers</div>
      <div className="w-full flex-grow space-y-2">
        <textarea
          rows="4"
          cols="50"
          placeholder="Enter mobile numbers here..."
          value={input}
          onChange={handleInputChange}
          className={`custom-scrollbar w-full h-32 bg-pbg text-ptxt px-5 py-4 border-2  focus:outline-none resize-none ${
            errors.length > 0 ? "border-cpink" : "border-pbg"
          }`}
        />
        <div className="flex gap-4 items-end">
          <div className="select-none">
            <img className="size-6" src={infoIcon} alt="" />
          </div>
          <p className="text-ttxt">Format: 923XXXXXXX, 923XXXXXXXXX</p>
        </div>
        {errors.length > 0 ? (
          <div className="flex gap-4 items-end">
            <div className="my-anchor-element cursor-help">
              <img className="size-6" src={infoIconRed} alt="" />
            </div>
            <p className="text-cpink">
              Please correct the following&nbsp;
              <span className="underline hover:font-medium cursor-help my-anchor-element">
                issues
              </span>
              :
              <Tooltip
                anchorSelect=".my-anchor-element"
                delayHide={1000}
                place="top"
              >
                <div>
                  <ul>
                    {errors.map((error) => (
                      <li key={error.index}>{error.message}</li>
                    ))}
                  </ul>
                </div>
              </Tooltip>
            </p>
          </div>
        ) : (
          <div className="h-10"></div>
        )}
      </div>
      <div className="mx-5 ">
        <button disabled={input.length < 10} type="submit" className="btn">
          <span className="px-14">Next</span>
        </button>
      </div>
    </form>
  );
};

export default MultipleMsisdnTopupEnterNumber;
