import React from "react";

const IconTitleComponent = ({ iconSrc, title }) => {
  return (
    <div className="flex gap-6 items-center">
      <img src={iconSrc} className="size-[3.375em]" alt="" />
      <p className="font-light text-3xl">{title}</p>
    </div>
  );
};

export default IconTitleComponent;
