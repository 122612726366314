import React, { useRef } from "react";
import LowBalanceAlert from "./LowBalanceAlert";
import TransactionHistory from "./TransactionHistory";
import useGetAccountDetails from "../../hooks/useGetAccountDetails";

const Wallet = () => {
  const transactionHistoryRef = useRef();
  const { getAccountDetails } = useGetAccountDetails();
  const refreshTransactionsData = () => {
    if (transactionHistoryRef.current) {
      transactionHistoryRef.current.refreshTransactionsData();
      getAccountDetails();
    }
  };

  return (
    <section className="space-y-7">
      <div className="flex gap-7 items-stretch flex-wrap">
        <div className="min-w-[30em] laptop:w-1/5 flex-grow h-full">
          <LowBalanceAlert refreshTransactionsData={refreshTransactionsData} />
        </div>
        <div className="min-w-[30em] flex-grow h-full opacity-0 pointer-events-none w-[333px]"></div>
      </div>
      <TransactionHistory ref={transactionHistoryRef} />
    </section>
  );
};

export default Wallet;
