import React, { useState } from "react";
import forgetPasswordPageImage from "../assets/images/forget-password-page-image.png";
import emailIcon from "../assets/svgs/email-icon.svg";
import emailIconFocused from "../assets/svgs/email-icon-focused.svg";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase"; // Firebase instance
// import { useNavigate } from "react-router-dom";
import PATHS from "../constants/RoutePaths";
import CustomLink from "../reusable/CustomLink";
import AuthPageComponent from "../reusable/AuthPageComponent";
import InputField from "../reusable/InputField";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  //   const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent successfully!");
      setIsError(false);
    } catch (error) {
      setMessage("Failed to send password reset email. Please try again.");
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthPageComponent
      imgSrcLeft={forgetPasswordPageImage}
      titleRight={"Forget Password?"}
      descriptionRight={
        "Enter the email associated with your account. We will send an email with instructions to reset your password."
      }
    >
      <div className="pb-[10vh]">
        <form onSubmit={handleForgotPassword} className="w-full space-y-10">
          {/* If there's a message from the URL, show it here */}
          {message && (
            <div
              className={`mb-4 text-center ${
                isError ? "text-cpink" : "text-cgreen"
              } bg-gray-700 p-2 rounded-md`}
            >
              {message}
            </div>
          )}
          <InputField
            icon={emailIcon}
            iconFocused={emailIconFocused}
            placeholder={"Email"}
            name={"email"}
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
          <div className="space-y-5">
            <button
              disabled={loading || email.trim().length === 0}
              type="submit"
              className="btn w-full rounded-md"
            >
              {loading ? "Sending..." : "Send"}
            </button>
            <div className="w-full flex justify-center">
              <CustomLink
                disabled={loading}
                to={PATHS.SIGN_IN}
                className="text-lg text-ttxt text-center hover:underline"
              >
                Back to login screen
              </CustomLink>
            </div>
          </div>
        </form>
      </div>
    </AuthPageComponent>
  );
};

export default ForgotPassword;
