import React, { useContext } from "react";
import { deviceContext as DeviceContext } from "./context";

export const DeviceContextProvider = ({ children }) => {
  return <DeviceContext.Provider value={{}}>{children}</DeviceContext.Provider>;
};

const useDevice = () => useContext(DeviceContext);

export default useDevice;
