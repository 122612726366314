const formatDate = (dateString) => {
  let date;
  if (Array.isArray(dateString)) {
    const [year, month, day] = dateString;

    // Create a new date object (months are 0-indexed in JavaScript, so we subtract 1 from month)
    date = new Date(year, month - 1, day);
  } else {
    date = new Date(dateString);
  }

  // Format the date using Intl.DateTimeFormat
  const day = date.getDate().toString().padStart(2, "0"); // ensures 2 digits for day
  // const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export default formatDate;
