/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";
import { useState } from "react";
import useApi from "../useApi";
import ApiConstants from "../../constants/ApiConstants";
import { findTransactionStatus } from "../../constants/wallet/TransactionStatuses";
import useHandlePage from "../useHandlePage";

const useGetWalletTransactions = (
  initialState = {
    page: 0,
    size: 5,
  }
) => {
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [filter, setFilterState] = useState(initialState);
  const { makeApiCall, loading } = useApi();

  const formattedData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        status: findTransactionStatus(item?.statusCode),
      })),
    [data]
  );

  const updateFilter = (page, size) => {
    setFilterState({
      page,
      size,
    });
  };

  const getWalletTransactions = async () => {
    if (!filter) {
      return;
    }
    try {
      const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_WALLET_GET_TRANSCACTIONS}`;
      const response = await makeApiCall({
        url,
        params: {
          page: filter?.page,
          size: filter?.size,
        },
      });
      /*   
          const response = {
            data: {
              data: getMockData(filter?.page, filter?.size),
            },
          };
      */
      const { data } = response?.data;
      setData(Array.isArray(data?.content) ? data?.content : []);
      setDataInfo({
        totalEntries: data?.totalEntries,
        totalPages: data?.totalPages,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (filter) {
      getWalletTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const { page, setPage, pageSize, setPageSize } = useHandlePage(
    filter,
    setFilterState
  );

  return {
    data,
    formattedData,
    totalEntries: dataInfo?.totalEntries,
    totalPages: dataInfo?.totalPages,
    updateFilter,
    loading,
    refreshTransactionsData: getWalletTransactions,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

export default useGetWalletTransactions;

const mockData = [
  {
    transactionId: 1,
    accountTitle: "John Doe",
    cardNumber: {
      first4: "1234",
      last4: "5678",
    },
    amount: 100.5,
    statusCode: 1,
    statusDescription: "Success",
    date: "2024-12-01T10:15:30Z",
  },
  {
    transactionId: 2,
    accountTitle: "Jane Smith",
    cardNumber: {
      first4: "4321",
      last4: "8765",
    },
    amount: 50.75,
    statusCode: 2,
    statusDescription: "Fail",
    date: "2024-12-01T11:00:00Z",
  },
  {
    transactionId: 3,
    accountTitle: "Alice Johnson",
    cardNumber: {
      first4: "1111",
      last4: "2222",
    },
    amount: 200.0,
    statusCode: 1,
    statusDescription: "Success",
    date: "2024-12-01T11:30:45Z",
  },
  {
    transactionId: 4,
    accountTitle: "Bob Brown",
    cardNumber: {
      first4: "3333",
      last4: "4444",
    },
    amount: 15.0,
    statusCode: 2,
    statusDescription: "Fail",
    date: "2024-12-01T12:00:00Z",
  },
  {
    transactionId: 5,
    accountTitle: "Carol Lee",
    cardNumber: {
      first4: "5555",
      last4: "6666",
    },
    amount: 123.45,
    statusCode: 1,
    statusDescription: "Success",
    date: "2024-12-01T12:15:30Z",
  },
  {
    transactionId: 6,
    accountTitle: "David King",
    cardNumber: {
      first4: "7777",
      last4: "8888",
    },
    amount: 0.0,
    statusCode: 2,
    statusDescription: "Fail",
    date: "2024-12-01T13:00:00Z",
  },
  {
    transactionId: 7,
    accountTitle: "Eve Adams",
    cardNumber: {
      first4: "9999",
      last4: "0000",
    },
    amount: 300.99,
    statusCode: 1,
    statusDescription: "Success",
    date: "2024-12-01T13:30:00Z",
  },
  {
    transactionId: 8,
    accountTitle: "Frank Harris",
    cardNumber: {
      first4: "2222",
      last4: "3333",
    },
    amount: 89.95,
    statusCode: 2,
    statusDescription: "Fail",
    date: "2024-12-01T14:00:00Z",
  },
  {
    transactionId: 9,
    accountTitle: "Grace Wilson",
    cardNumber: {
      first4: "4444",
      last4: "5555",
    },
    amount: 150.0,
    statusCode: 1,
    statusDescription: "Success",
    date: "2024-12-01T14:30:00Z",
  },
  {
    transactionId: 10,
    accountTitle: "Hank Morgan",
    cardNumber: {
      first4: "6666",
      last4: "7777",
    },
    amount: 75.25,
    statusCode: 2,
    statusDescription: "Fail",
    date: "2024-12-01T15:00:00Z",
  },
];

const getMockData = (page, size) => {
  const start = page * size;
  const end = start + size;
  return {
    content: mockData.slice(start, end),
    totalEntries: mockData.length,
    totalPages: Math.ceil(mockData.length / size),
  };
};
